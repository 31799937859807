import React, { Component } from 'react'
import { get, put } from '../../utils/httpAgent'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'

class DetailsForm extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      name: '',
      company: '',
      phone: '',
      zip: ''
    }
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData () {
    get(`/1/admin/account/${this.props.aid}`)
      .then(r => {
        this.setState({
          name: r.data.name,
          company: r.data.company,
          phone: r.data.phone,
          zip: r.data.zip
        })
      })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put('/1/admin/account/' + this.props.aid, {
      name: this.input.name.value(),
      company: this.input.company.value(),
      phone: this.input.phone.value(),
      zip: this.input.zip.value()
    }).then(r => {
      if (r.success === true) {
        this.setState({
          success: true,
          error: '',
          loading: false,
          hasError: {}
        })
      } else {
        const state = {
          success: false,
          error: '',
          loading: false,
          hasError: {},
          help: {}
        }
        for (const key in r.errfor) {
          state.hasError[key] = true
          state.help[key] = r.errfor[key]
        }

        if (r.errors[0] !== undefined) {
          state.error = r.errors[0]
        }
        this.setState(state)
      }
    })
  } // end handleSubmit

  render () {
    let alerts = []

    if (this.state.success) {
      alerts = (
        <Alert
          type='success'
          message='個人資料更新成功'
        />
      )
    } else if (this.state.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    return (
      <form onSubmit={this.handleSubmit.bind(this)} className='mb-5'>
        <legend> 個人資料 </legend>
        {alerts}
        <TextControl
          className='form-control mr-auto ml-auto mb-3'
          ref={(c) => (this.input.name = c)}
          name='name'
          label='名稱'
          value={this.state.name}
          onChange={(e) => (this.setState({ name: e.target.value }))}
          hasError={this.state.hasError.name}
          help={this.state.help.name}
          disabled={this.state.loading}
        />
        <TextControl
          className='form-control mr-auto ml-auto mb-3'
          ref={(c) => (this.input.company = c)}
          name='company'
          label='公司'
          value={this.state.company}
          onChange={(e) => (this.setState({ company: e.target.value }))}
          hasError={this.state.hasError.company}
          help={this.state.help.company}
          disabled={this.state.loading}
        />
        <TextControl
          className='form-control mr-auto ml-auto mb-3'
          ref={(c) => (this.input.phone = c)}
          name='phone'
          label='電話'
          value={this.state.phone}
          onChange={(e) => (this.setState({ phone: e.target.value }))}
          hasError={this.state.hasError.phone}
          help={this.state.help.phone}
          disabled={this.state.loading}
        />
        <TextControl
          className='form-control mr-auto ml-auto mb-3'
          ref={(c) => (this.input.zip = c)}
          name='zip'
          label='郵遞區號'
          type='number'
          value={this.state.zip}
          onChange={(e) => (this.setState({ zip: e.target.value }))}
          hasError={this.state.hasError.zip}
          help={this.state.help.zip}
          disabled={this.state.loading}
        />
        <ControlGroup hideLabel hideHelp>
          <Button
            type='submit'
            className='btn sign-btn'
            disabled={this.props.loading}
          >
            更新
            <Spinner
              space='left'
              show={this.props.loading}
            />
          </Button>
        </ControlGroup>
      </form>
    )
  }
}

export default DetailsForm

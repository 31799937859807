import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import { get, put } from '../utils/httpAgent'
import Hero from '../components/hero'
import FooterDownload from '../components/footer-download'
import Spinner from '../components/spinner'
import AccountMenu from '../components/account-menu'
import Alert from '../shared/alert'

class PlansPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      permission: null,
      status: '',
      payType: '',
      showReturnModal: false,
      showCancelModal: false,
      showResubscribeModal: false
    }
    this.setInfo = this.setInfo.bind(this)
    this.handleReturn = this.handleReturn.bind(this)
    this.toggleReturnModal = this.toggleReturnModal.bind(this)
    this.toggleCancelModal = this.toggleCancelModal.bind(this)
    this.toggleResubscribeModal = this.toggleResubscribeModal.bind(this)
  }

  componentDidMount () {
    const subId = this.getParameterByName('subscription_id')
    if (subId) {
      this.handleReturn(subId)
    } else {
      this.fetchUserData()
      this.fetchSubscriptionData()
    }
  }

  fetchUserData () {
    get('/1/account/personal')
      .then(r => {
        if (r.success && r.data) {
          this.setState({
            permission: r.data.permission,
            plan: r.data.plan,
            payType: r.data.payType,
            expiryDate: r.data.expiryDate
          })
        }
      })
  }

  fetchSubscriptionData () {
    get('/1/account/subscription')
      .then(r => {
        if (r.success && r.data) {
          this.setState({
            subId: r.data._id,
            status: r.data.status,
            description: r.data.plan.description,
            nextBillingTime: r.data.nextBillingTime,
            loading: false
          })
        }
      })
  }

  getParameterByName (name) {
    name = name.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(window.location.href)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  handleReturn (subscriptionId) {
    this.setState({
      loading: true
    })

    put(`/1/account/subscription/paypal/${subscriptionId}/return`, {}).then(
      r => {
        if (r.success === true) {
          this.toggleReturnModal()
          this.fetchUserData()
          this.fetchSubscriptionData()
          window.history.replaceState({}, '', window.location.pathname + window.location.hash)
          this.setState({
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  setInfo (input) {
    this.setState({ ...input })
  }

  handlePaypalSuspend (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put(`/1/account/subscription/${this.state.subId}/suspend`, {}).then(r => {
      if (r.success === true) {
        this.fetchSubscriptionData()
        this.setState({ showCancelModal: false })
      } else {
        const state = {
          success: false,
          error: '',
          loading: false,
          hasError: {},
          help: {}
        }
        for (const key in r.errfor) {
          state.hasError[key] = true
          state.help[key] = r.errfor[key]
        }

        if (r.errors[0] !== undefined) {
          state.error = r.errors[0]
        }
        this.setState(state)
      }
    })
  }

  handleECPayCancel (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put(`/1/account/ecpay/${this.state.subId}/cancel`, {}).then(r => {
      if (r.success === true) {
        this.fetchSubscriptionData()
        this.setState({ showCancelModal: false })
      } else {
        const state = {
          success: false,
          error: '',
          loading: false,
          hasError: {},
          help: {}
        }
        for (const key in r.errfor) {
          state.hasError[key] = true
          state.help[key] = r.errfor[key]
        }

        if (r.errors[0] !== undefined) {
          state.error = r.errors[0]
        }
        this.setState(state)
      }
    })
  }

  handleActive (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put(`/1/account/subscription/${this.state.subId}/active`, {}).then(r => {
      if (r.success === true) {
        this.fetchSubscriptionData()
        this.setState({ showResubscribeModal: false })
      } else {
        const state = {
          success: false,
          error: '',
          loading: false,
          hasError: {},
          help: {}
        }
        for (const key in r.errfor) {
          state.hasError[key] = true
          state.help[key] = r.errfor[key]
        }

        if (r.errors[0] !== undefined) {
          state.error = r.errors[0]
        }
        this.setState(state)
      }
    })
  }

  toggleReturnModal () {
    this.setState({ showReturnModal: !this.state.showReturnModal })
  }

  toggleCancelModal () {
    this.setState({ showCancelModal: !this.state.showCancelModal })
  }

  toggleResubscribeModal () {
    this.setState({ showResubscribeModal: !this.state.showResubscribeModal })
  }

  render () {
    const { t } = this.props
    let alerts = []

    if (this.state.success) {
      alerts = (
        <Alert
          type='success'
          message={t('changes_saved')}
        />
      )
    } else if (this.state.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    return (
      <div>
        <Helmet>
          <title>{t('plans')}</title>
        </Helmet>

        <Hero type={1} />

        <AccountMenu t={t} />

        <section id='about'>
          <Container className='mb-5 pb-5'>
            <h1 className='text-center'>{t('plans')}</h1>
            <Col md={12} className='text-center'>
              {alerts}
            </Col>
            <Row>
              {((this.state.permission && this.state.permission >= 300) || ['ACTIVE', 'SUSPENDED', 1].includes(this.state.status)) &&
                <Col md={12} className='text-center'>
                  <h3>{this.state.plan}</h3>
                  <p className='text-center'>
                    {this.state.description}
                  </p>
                  <h3>{t('plan_expires')}</h3>
                  <p className='text-center'>
                    {new Date(this.state.expiryDate).toLocaleDateString()}
                  &nbsp;
                    {this.state.status === 'ACTIVE' &&
                    `(${t('auto_renewal')})`}
                  </p>
                  {['ACTIVE', 1].includes(this.state.status) &&
                    <>
                      <Button
                        color=''
                        className='mt-3 btn-outline-default'
                        onClick={e => this.toggleCancelModal()}
                        disabled={this.state.loading}
                      >
                        {t('cancel_subscribe')}
                        <Spinner space='left' show={this.state.loading} />
                      </Button>
                      <p className='text-center gray-sm mt-3'>
                        {`${t('if_cancel')} ${new Date(this.state.expiryDate).toLocaleDateString()}${t('dot')}`}
                      </p>
                    </>}
                  {this.state.status === 'SUSPENDED' &&
                    <>
                      <Button
                        color=''
                        className='mt-3 btn-outline-primary'
                        onClick={e => this.toggleResubscribeModal()}
                        disabled={this.state.loading}
                      >
                        {t('resubscribe')}
                        <Spinner space='left' show={this.state.loading} />
                      </Button>
                      <p className='text-center gray-sm mt-3'>
                        {`${t('if_resubscribe')}${new Date(this.state.expiryDate).getTime() > new Date().getTime() ? new Date(this.state.expiryDate).toLocaleDateString() : new Date().toLocaleDateString()}${t('dot')}`}
                      </p>
                    </>}
                </Col>}

              {((this.state.permission && this.state.permission <= 200) || ['CANCELLED', 'EXPIRED', 0].includes(this.state.status)) &&
                <Col md={12} className='text-center'>
                  <p className='text-center gray'>
                    {t('no_active_plans')}
                  </p>
                  <a
                    href='/pricing/'
                    className='btn sign-btn'
                  >
                    {t('see_plans')}
                  </a>
                </Col>}
            </Row>
          </Container>
        </section>

        <FooterDownload t={this.props.t} />

        <Modal
          isOpen={this.state.showCancelModal}
          toggle={e => this.toggleCancelModal()}
          modalClassName='subscribe-modal'
          backdrop={false}
        >
          <ModalBody>
            <p className='text-center mb-0'>
              {t('sure_cancel_subscribe')}
            </p>
          </ModalBody>
          <ModalFooter className='pt-0'>
            <Button
              className='btn mr-auto ml-auto'
              onClick={e => this.toggleCancelModal()}
            >
              {t('cancel')}
            </Button>
            {['paypal', ''].includes(this.state.payType) &&
              <Button
                className='btn mr-auto ml-auto'
                onClick={this.handlePaypalSuspend.bind(this)}
              >
                {t('ok')}
              </Button>}
            {this.state.payType === 'ecpay' &&
              <Button
                className='btn mr-auto ml-auto'
                onClick={this.handleECPayCancel.bind(this)}
              >
                {t('ok')}
              </Button>}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.showResubscribeModal}
          toggle={e => this.toggleResubscribeModal()}
          modalClassName='subscribe-modal'
          backdrop={false}
        >
          <ModalBody>
            <p className='text-center mb-0'>
              {t('sure_to_resubscribe')}
            </p>
          </ModalBody>
          <ModalFooter className='pt-0'>
            <Button
              className='btn mr-auto ml-auto'
              onClick={e => this.toggleResubscribeModal()}
            >
              {t('cancel')}
            </Button>
            <Button
              className='btn mr-auto ml-auto btn-primary'
              onClick={this.handleActive.bind(this)}
            >
              {t('ok')}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.showReturnModal}
          toggle={e => this.toggleReturnModal()}
          modalClassName='subscribe-modal modal-toast'
          backdrop={false}
        >
          <ModalBody className='text-center'>
            <p className='text-center mb-0'>
              {t('payment_successful')}
            </p>
          </ModalBody>
          <ModalFooter className='pt-3'>
            <Button
              color=''
              className='mt-3 mb-3 mr-auto ml-auto btn-outline-white'
              onClick={e => this.toggleReturnModal()}
            >
              {t('i_see')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default PlansPage

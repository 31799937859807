import React, { Component } from 'react'
import { loginSuccess, loginError } from '../utils/userAction'
import { Helmet } from 'react-helmet'
import { Container, Row, Col, Button } from 'reactstrap'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from 'react-google-login'
import { post } from '../utils/httpAgent'
import { encrypt } from '../utils/rsa'
import Alert from '../shared/alert'
import Spinner from '../components/spinner'
import TextControl from '../components/text-control'
import Hero from '../components/hero'
import FooterDownload from '../components/footer-download'
import ShowButton from '../components/show-button'
import config from '../config'

class Login extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      role: '',
      returnUrl: this.getParameterByName('returnUrl'),
      showPassword: false
    }
    this.showPassword = this.showPassword.bind(this)
    this.responseSSO = this.responseSSO.bind(this)
  }

  componentDidMount () {
    if (this.input.username) {
      this.input.username.focus()
    }
  }

  componentWillUnmount () {
    this.setState = () => false
  }

  getParameterByName (name) {
    name = name.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(window.location.href)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/login', {
      username: this.input.username.value(),
      password: encrypt(this.input.password.value()),
      device: 'web',
      hasEncrypt: 'true'
    }).then(
      r => {
        if (r.success === true && r.data) {
          localStorage.setItem('token', r.data.token)
          delete r.data.token
          this.props.loginSuccess(r.data)
          this.setState({
            success: true,
            error: '',
            loading: false,
            role: r.data.role
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
          this.props.loginError()
        }
      }
    )
  }

  handleKeyPressn (e) {
    if (e.key === 'Enter') {
      this.setLogin()
    }
  }

  showPassword (e) {
    e.preventDefault()
    this.setState(prevState => ({ showPassword: !prevState.showPassword }))
  }

  responseSSO (response, sso) {
    if (!response.accessToken) {
      let error = this.props.t('login_err')
      if (response.details) {
        error = response.details
      }
      this.setState({
        success: false,
        error: error,
        loading: false,
        hasError: {},
        help: {}
      })
    } else if (!response.email && (response.profileObj && !response.profileObj.email)) {
      this.setState({
        success: false,
        error: this.props.t('email_required'),
        loading: false,
        hasError: {},
        help: {}
      })
    } else {
      this.setState({
        loading: true
      })

      post(`/1/auth/${sso}/token?access_token=${response.accessToken}`, {
        device: 'web'
      }).then(
        r => {
          if (r.success === true) {
            localStorage.setItem('token', r.data.token)
            delete r.data.token
            this.props.loginSuccess(r.data)
            this.setState({
              success: true,
              error: '',
              loading: false,
              role: r.data.role
            })
          } else {
            const state = {
              success: false,
              error: '',
              loading: false,
              hasError: {},
              help: {}
            }
            for (const key in r.errfor) {
              state.hasError[key] = true
              state.help[key] = r.errfor[key]
            }

            if (r.errors[0] !== undefined) {
              state.error = r.errors[0]
            }
            this.setState(state)
            this.props.loginError()
          }
        }
      )
    }
  }

  render () {
    const { t } = this.props
    let alerts = []
    if (this.state.success) {
      if (this.state.returnUrl) {
        if (this.state.returnUrl.includes('/app/')) {
          return (<Redirect to={this.state.returnUrl} />)
        } else {
          window.location.assign(this.state.returnUrl)
        }
      } else if (this.state.role) {
        return (<Redirect to={`/app/${this.state.role}/${this.state.role === 'account' ? 'plans' : ''}`} />)
      }
    } else if (this.props.role) {
      if (this.state.returnUrl) {
        if (this.state.returnUrl.includes('/app/')) {
          return (<Redirect to={this.state.returnUrl} />)
        } else {
          window.location.assign(this.state.returnUrl)
        }
      } else {
        return (<Redirect to={`/app/${this.props.role}/${this.props.role === 'account' ? 'plans' : ''}`} />)
      }
    } else if (this.state.error) {
      if (this.state.errorCounter > 2) {
        return (<Redirect to='/app/login/forgot' />)
      } else {
        alerts = (
          <Alert
            type='danger'
            message={this.state.error}
          />
        )
      }
    }

    if (this.state.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    return (
      <div>
        <Helmet>
          <title>{t('sign_in')}</title>
        </Helmet>

        <Hero />

        <section id='about'>
          <Container className='mb-5 pb-5'>
            <h1 className='text-center'>{t('sign_in_cupola')}</h1>
            <Row>
              <Col md={12} className='text-center'>
                {alerts}
                <TextControl
                  className='form-control form-control-lg mr-auto ml-auto mb-3'
                  ref={(c) => (this.input.username = c)}
                  name='username'
                  placeholder={t('email_address')}
                  hasError={this.state.hasError.username}
                  disabled={this.state.loading}
                  hideHelp
                />
                <div className='password'>
                  <TextControl
                    className='form-control form-control-lg mr-auto ml-auto'
                    ref={(c) => (this.input.password = c)}
                    name='password'
                    placeholder={t('password')}
                    type={this.state.showPassword ? 'text' : 'password'}
                    hasError={this.state.hasError.password}
                    disabled={this.state.loading}
                    hideHelp
                  />
                  {!this.state.hasError.password &&
                    <ShowButton
                      show={this.state.showPassword}
                      onClick={e => this.showPassword(e)}
                    />}
                </div>
                <p className='text-center'>
                  <Link className='forgot-text' to='/app/login/forgot'>{t('forgot_password')}</Link>
                </p>
                <Button
                  color=''
                  className='sign-btn'
                  onClick={this.handleSubmit.bind(this)}
                  disabled={this.state.loading}
                >
                  {t('sign_in')}
                  <Spinner space='left' show={this.state.loading} />
                </Button>
                <p className='text-center sso-text'>{t('sign_in_with')}</p>
                <FacebookLogin
                  appId={config.oauth.facebook}
                  fields='name,email'
                  callback={e => this.responseSSO(e, 'facebook')}
                  render={renderProps => (
                    <button className='login-btn login-btn-facebook' onClick={renderProps.onClick}>
                      {t('facebook_sign_in')}
                    </button>
                  )}
                />
                <GoogleLogin
                  clientId={config.oauth.google}
                  render={renderProps => (
                    <button className='login-btn login-btn-google' onClick={renderProps.onClick} disabled={renderProps.disabled}>
                      {t('google_sign_in')}
                    </button>
                  )}
                  autoLoad={false}
                  onSuccess={e => this.responseSSO(e, 'google')}
                  onFailure={e => this.responseSSO(e, 'google')}
                />
                <p className='text-center signup-text'>{t('dont_have_account')} <Link to='/app/signup'>{t('create_account')}</Link></p>
              </Col>
            </Row>
          </Container>
        </section>

        <FooterDownload t={this.props.t} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.index.user,
  authenticated: state.index.authenticated,
  role: state.index.role
})

const mapDispatchToProps = dispatch => ({
  loginSuccess (user) {
    dispatch(loginSuccess(user))
  },
  loginError () {
    dispatch(loginError())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import { putFormData, get } from '../../utils/httpAgent'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import DateTimeControl from '../../components/datetime-control'
import SelectControl from '../../components/select-control'
import FileControl from '../../components/file-control'
import config from '../../config'

class EditNewsPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      name: '',
      lang: '',
      postTime: new Date(),
      content: [],
      image: ''
    }
  }

  componentDidMount () {
    this.fetchData()
    if (this.input.name) {
      this.input.name.focus()
    }
  }

  handleChange (event, editor) {
    const content = editor.getData()
    this.setState({ content: content })
  }

  fetchData () {
    get(`/1/news/${this.props.match.params.id}`)
      .then(r => {
        this.setState({
          id: r.data._id,
          name: r.data.name,
          image: r.data.image,
          lang: r.data.lang,
          postTime: new Date(r.data.postTime),
          content: r.data.content,
          isActive: r.data.isActive.toString()
        })
      })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    putFormData(`/1/admin/news/${this.props.match.params.id}`, {
      name: this.input.name.value(),
      file: this.input.file.value() ? this.input.file.value() : '',
      lang: this.input.lang.value(),
      postTime: this.state.postTime.getTime(),
      content: this.state.content,
      isActive: this.input.isActive.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    let alert = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/news' />)
    } else if (this.state.error) {
      alert =
        <Alert
          type='danger'
          message={this.state.error}
        />
    }

    let postDate = new Date(this.state.postTime)
    postDate = postDate.getFullYear() + '.' + ('0' + (postDate.getMonth() + 1)).substr(-2) + '.' + ('0' + postDate.getDate()).substr(-2)

    return (
      <Container className='mt-5 mb-5 pb-5'>
        <Helmet>
          <title>編輯最新消息 {this.state.name}</title>
        </Helmet>
        <h1 className='page-header'>編輯最新消息</h1>
        <Col md={12} className='text-center'>
          {alert}
        </Col>
        <Row>
          <Col md='6'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              <TextControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.name = c)}
                name='name'
                label='標題'
                placeholder='新聞標題'
                value={this.state.name}
                onChange={(e) => (this.setState({ name: e.target.value }))}
                hasError={this.state.hasError.name}
                disabled={this.state.loading}
              />
              <FileControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.file = c)}
                name='file'
                label='檔案'
                hasError={this.state.hasError.file}
                disabled={this.state.loading || this.state.url}
              />
              { this.state.image &&
                <div className='mb-3'>
                  <a href={`${config.awsUrl}${this.state.image}`} target='_blank' rel='noopener noreferrer'>
                    當前檔案
                  </a>
                </div>
              }
              <SelectControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.lang = c)}
                name='lang'
                label='語系'
                value={this.state.lang}
                onChange={(e) => (this.setState({ lang: e.target.value }))}
                hasError={this.state.hasError.lang}
                disabled={this.state.loading}
              >
                <option value='tw'>
                  繁體中文
                </option>
                <option value='en'>
                  英文
                </option>
              </SelectControl>

              <DateTimeControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.postTime = c)}
                name='postTime'
                label='發布日期'
                placeholder='新聞發布日期'
                timeFormat={false}
                value={this.state.postTime}
                onChange={(e) => this.setState({ postTime: new Date(e.valueOf()) })}
                hasError={this.state.hasError.postTime}
                disabled={this.state.loading}
              />

              <div className='form-group'>
                <label className='control-label'>
                  內文
                </label>
                <div className='news'>
                  <CKEditor
                    className='news'
                    config={{ ckfinder: {
                      uploadUrl: '/app/1/content/storage'
                    } }}
                    editor={ClassicEditor}
                    data={this.state.content}
                    onInit={editor => {}}
                    onChange={this.handleChange.bind(this)}
                    onBlur={(event, editor) => {}}
                    onFocus={(event, editor) => {}}
                  />
                </div>
              </div>

              <SelectControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.isActive = c)}
                name='isActive'
                label='是否啟用'
                value={this.state.isActive}
                onChange={(e) => (this.setState({ isActive: e.target.value }))}
                hasError={this.state.hasError.isActive}
                disabled={this.state.loading}
              >
                <option value='true'>
                  啟用
                </option>
                <option value='false'>
                  關閉
                </option>
              </SelectControl>
              <ControlGroup hideLabel hideHelp>
                <Button
                  className='btn sign-btn mt-3'
                  type='submit'
                  disabled={this.state.loading}
                >
                  更新
                  <Spinner space='left' show={this.state.loading} />
                </Button>
              </ControlGroup>
            </form>
          </Col>
          <Col md='6'>
            <Card>
              <CardBody className='news'>
                <h2>{this.state.name}</h2>
                <p className='post-time'>{postDate}</p>
                <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default EditNewsPage

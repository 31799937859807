import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter } from 'reactstrap'
import { Link } from 'react-router-dom'
import { get, put, deleteItem } from '../utils/httpAgent'
import DetailsForm from './details-form'
// import UserForm from './user-form'
import PasswordForm from './password-form'
import Hero from '../components/hero'
import FooterDownload from '../components/footer-download'
import Spinner from '../components/spinner'
import AccountMenu from '../components/account-menu'
import Alert from '../shared/alert'
import { encrypt } from '../utils/rsa'

class SettingsPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      name: '',
      email: '',
      newPassword: '',
      confirm: '',
      showDeleteAccountModal: false
    }
    this.setInfo = this.setInfo.bind(this)
    this.showDeleteAccountModal = this.showDeleteAccountModal.bind(this)
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData () {
    get('/1/account/personal')
      .then(r => {
        if (r.data) {
          this.setState({
            name: r.data.name,
            email: r.data.email
          })
        }
      })
  }

  setInfo (input) {
    this.setState({ ...input })
  }

  showDeleteAccountModal () {
    this.setState({ showDeleteAccountModal: !this.state.showDeleteAccountModal })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put('/1/account/personal', {
      name: this.state.name,
      newPassword: encrypt(this.state.newPassword),
      confirm: encrypt(this.state.confirm),
      hasEncrypt: 'true'
    }).then(r => {
      if (r.success === true) {
        this.setState({
          success: true,
          error: '',
          loading: false,
          hasError: {}
        })
      } else {
        let state = {
          success: false,
          error: '',
          loading: false,
          hasError: {},
          help: {}
        }
        for (let key in r.errfor) {
          state.hasError[key] = true
          state.help[key] = r.errfor[key]
        }

        if (r.errors[0] !== undefined) {
          state.error = r.errors[0]
        }
        this.setState(state)
      }
    })
  } // end handleSubmit

  handleDeleteAccount (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    deleteItem('/1/account/personal', {}).then(r => {
      if (r.success === true) {
        localStorage.removeItem('token')
        window.location.assign('/app/')
      } else {
        let state = {
          success: false,
          error: '',
          loading: false,
          hasError: {},
          help: {}
        }
        for (let key in r.errfor) {
          state.hasError[key] = true
          state.help[key] = r.errfor[key]
        }

        if (r.errors[0] !== undefined) {
          state.error = r.errors[0]
        }
        this.setState(state)
      }
    })
  }

  render () {
    const { t } = this.props
    let alerts = []

    if (this.state.success) {
      alerts = <Alert
        type='success'
        message={t('changes_saved')}
      />
    } else if (this.state.error) {
      alerts = <Alert
        type='danger'
        message={this.state.error}
      />
    }

    return (
      <div>
        <Helmet>
          <title>{t('profile')}</title>
        </Helmet>

        <Hero type={1} />

        <AccountMenu t={t} />

        <section id='about'>
          <Container className='mb-5'>
            <h1 className='text-center'>{t('profile')}</h1>
            <Col md={12} className='text-center'>
              {alerts}
            </Col>
            <Row>
              <DetailsForm
                t={t}
                name={this.state.name}
                email={this.state.email}
                hasError={this.state.hasError}
                help={this.state.help}
                error={this.state.error}
                success={this.state.success}
                setInfo={e => this.setInfo(e)}
              />
              <PasswordForm
                t={t}
                hasError={this.state.hasError}
                help={this.state.help}
                error={this.state.error}
                success={this.state.success}
                setInfo={e => this.setInfo(e)}
              />
              <Col md={12} className='text-center mt-4'>
                <Button
                  color=''
                  className='sign-btn'
                  onClick={this.handleSubmit.bind(this)}
                  disabled={this.state.loading}>
                  {t('save')}
                  <Spinner space='left' show={this.state.loading} />
                </Button>
              </Col>
              <Col md={12} className='text-center mt-3'>
                <Link
                  to='/app/logout'
                  className='btn btn-outline-default'>
                  {t('sign_out')}
                </Link>
              </Col>
              <Col md={12} className='text-center mt-3'>
                <Button
                  color=''
                  to='/app/logout'
                  className='btn-link-gray'
                  onClick={e => this.showDeleteAccountModal()}
                  disabled={this.state.loading}>
                  {t('delete_account')}
                </Button>
                <p className='text-center gray-sm'>
                  {t('delete_account_description')}
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <FooterDownload t={this.props.t} />

        <Modal
          isOpen={this.state.showDeleteAccountModal}
          toggle={e => this.showDeleteAccountModal()}
          modalClassName='subscribe-modal'
          backdrop={false}
        >
          <ModalBody>
            <p className='text-center mb-0'>
              {t('sure_to_delete_account')}
            </p>
          </ModalBody>
          <ModalFooter className='pt-0'>
            <Button
              className='btn mr-auto ml-auto'
              onClick={e => this.showDeleteAccountModal()}
            >
              {t('cancel')}
            </Button>
            <Button
              className='btn mr-auto ml-auto btn-primary'
              onClick={this.handleDeleteAccount.bind(this)}
            >
              {t('ok')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default SettingsPage

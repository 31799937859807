import React from 'react'
import { connect } from 'react-redux'
import { loginError } from './userAction'

export function requireAdminAuth (Component) {
  class AuthenticatedComponent extends React.Component {
    componentDidMount () {
      try {
        var token = localStorage.getItem('token')
        if (token) {
          const jwtPayload = JSON.parse(decodeURIComponent(escape(window.atob((token.split('.')[1]).replace(/-/g, '+').replace(/_/g, '/')))))
          if (!jwtPayload._id || !jwtPayload.roles.admin) {
            this.loginError(!jwtPayload.roles.admin)
          }
        } else {
          this.loginError()
        }
      } catch (_error) {
        this.loginError()
      }
    }

    loginError (needLogout = false) {
      this.props.loginError()
      if (needLogout) {
        window.location.assign('/app/logout')
      } else if (window.location.pathname === '/' || window.location.pathname === '/app/') {
        window.location.assign('/app/')
      } else {
        if (window.location.search) {
          window.location.assign(`/app/?returnUrl=${window.location.pathname}&${window.location.search.substr(1)}`)
        } else {
          window.location.assign(`/app/?returnUrl=${window.location.pathname}`)
        }
      }
    }

    render () {
      return (
        <Component {...this.props} />
      )
    }
  }

  const mapDispatchToProps = dispatch => ({
    loginError () {
      dispatch(loginError())
    }
  })

  return connect(null, mapDispatchToProps)(AuthenticatedComponent)
}

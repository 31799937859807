const axios = require('axios').create({
  withCredentials: true
})

function getOptions () {
  let language = localStorage.getItem('lang') ? localStorage.getItem('lang') : navigator.language || navigator.userLanguage.includes('zh') ? 'zhTW' : 'en'
  if (localStorage.getItem('lang')) {
    language = localStorage.getItem('lang')
  } else if (navigator.language) {
    if (navigator.userLanguage === 'zh-TW') {
      language = 'zhTW'
    }
  } else {
    language = 'en'
  }
  const options = {
    headers: {
      lang: language
    }
  }
  if (localStorage.getItem('token')) {
    options.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  }

  return options
}

function exception (error) {
  console.error(error)
  if (error.response.status === 401) {
    localStorage.removeItem('token')
    if (window.location.pathname === '/' || window.location.pathname === '/app/') {
      window.location.assign('/app/')
    } else {
      window.location.assign(`/app/?returnUrl=${window.location.pathname}`)
    }
  }
  return {
    success: false,
    error: '資料連線出現錯誤。',
    errors: [],
    data: null
  }
}

export function get (uri) {
  const request = axios.get(urlParser(uri), getOptions())
    .then(r => {
      return r.data
    })
    .catch(function (e) {
      return exception(e)
    })

  return request
}

export function post (uri, data) {
  const request = axios.post(urlParser(uri), data, getOptions())
    .then(r => r.data)
    .catch(function (e) {
      return exception(e)
    })

  return request
}

export function postFormData (uri, data) {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })

  const request = axios.post(urlParser(uri), formData, getOptions())
    .then(r => r.data)
    .catch(function (e) {
      return exception(e)
    })

  return request
}

export function put (uri, data) {
  const request = axios.put(urlParser(uri), data, getOptions())
    .then(r => r.data)
    .catch(function (e) {
      return exception(e)
    })

  return request
}

export function putFormData (uri, data) {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })

  const request = axios.put(urlParser(uri), formData, getOptions())
    .then(r => r.data)
    .catch(function (e) {
      return exception(e)
    })

  return request
}

export function deleteItem (uri) {
  const request = axios.delete(urlParser(uri), getOptions())
    .then(r => r.data)
    .catch(function (e) {
      return exception(e)
    })

  return request
}

function urlParser (uri) {
  if (window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1' && !uri.includes('http')) {
    return `/app${uri}`
  } else {
    return uri
  }
}

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'reactstrap'
import { get } from '../../utils/httpAgent'
import DetailsForm from './details-form'
import UserForm from './user-form'
import TokensForm from './tokens-form'
import PasswordForm from './password-form'

class UserSettingsPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      roles: 'account',
      isActive: false,
      email: '',
      expiryDate: '',
      permission: 200,
      jwt: []
    }
  }

  componentDidMount () {
    this.fetchUser()
  }

  fetchUser () {
    get(`/1/admin/user/${this.props.match.params.uid}`)
      .then(r => {
        if (r.success && r.data) {
          this.setState({
            email: r.data.email,
            roles: r.data.roles.admin ? 'admin' : 'account',
            permission: r.data.permission,
            expiryDate: r.data.expiryDate ? new Date(r.data.expiryDate) : '',
            isActive: r.data.isActive,
            jwt: r.data.jwt
          })
        }
      })
      .catch(e => {
        console.error(e)
      })
  }

  render () {
    return (
      <Container>
        <Helmet>
          <title> {this.state.email} 帳號設定 </title>
        </Helmet>

        <h1 className='page-header'> {this.state.email} 帳號設定 </h1>
        <Row>
          <Col sm={6}>
            <DetailsForm aid={this.props.match.params.aid} />
            {this.state.email &&
              <UserForm
                uid={this.props.match.params.uid}
                email={this.state.email}
                roles={this.state.roles}
                permission={this.state.permission}
                expiryDate={this.state.expiryDate}
                isActive={this.state.isActive}
              />}
            <PasswordForm uid={this.props.match.params.uid} />
          </Col>
          <Col sm={6}>
            {this.state.jwt.length > 0 &&
              <TokensForm
                uid={this.props.match.params.uid}
                jwt={this.state.jwt}
              />}
          </Col>
        </Row>
      </Container>
    )
  }
}

export default UserSettingsPage

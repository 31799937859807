import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'

class Login extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      deepLink: `cupolastudio://cupola360.com/sso?access_token=${this.getParameterByName('access_token')}&state=${this.getParameterByName('state')}`
    }
  }

  componentDidMount () {
    window.location.replace(this.state.deepLink)
  }

  getParameterByName (name) {
    name = name.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp('[?&|#]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(window.location.href)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  render () {
    const { t } = this.props
    let title = ''
    if (this.getParameterByName('state') === 'facebook') {
      title = t('facebook_sign_in')
    } else if (this.getParameterByName('state') === 'google') {
      title = t('google_sign_in')
    }

    return (
      <div>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <section>
          <Container className='mt-5 mb-5 pb-5'>
            <Card className='pt-5 pb-5'>
              <CardBody>
                <h2 className='text-center'>
                  {title}
                </h2>
                <Row>
                  <Col md={12} className='text-center'>
                    <p className='text-center mb-5'>
                      {t('studio_should_start')}
                    </p>
                    <a
                      className='sign-btn a-block-btn mb-5'
                      href={this.state.deepLink}
                      role='button'
                    >
                      {t('start_studio')}
                    </a>
                  </Col>
                </Row>
              </CardBody>
            </Card>

          </Container>
        </section>
      </div>
    )
  }
}

export default Login

import React from 'react'
import ReactDOM from 'react-dom'
import { setDefaultLanguage, setDefaultTranslations } from 'react-multi-lang'
import en from './utils/translations/en.json'
import zhTW from './utils/translations/zh-TW.json'
import App from './App'
import * as serviceWorker from './serviceWorker'
import TagManager from 'react-gtm-module'

let defaultLanguage = localStorage.getItem('lang') ? localStorage.getItem('lang') : navigator.language || navigator.userLanguage.includes('zh') ? 'zh' : 'en'
if (localStorage.getItem('lang')) {
  defaultLanguage = localStorage.getItem('lang')
} else if (navigator.language) {
  defaultLanguage = 'zhTW'
} else {
  defaultLanguage = 'en'
}

setDefaultTranslations({ zhTW, en })
setDefaultLanguage(defaultLanguage)

const tagManagerArgs = {
  gtmId: 'GTM-KTXGH3F'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()

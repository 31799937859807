import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Helmet } from 'react-helmet'
import { Container, Row, Col, Button } from 'reactstrap'
import { post } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Spinner from '../components/spinner'
import TextControl from '../components/text-control'
import Hero from '../components/hero'
import FooterDownload from '../components/footer-download'

class ForgotPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {}
    }
  }

  componentDidMount () {
    if (this.input.email) {
      this.input.email.focus()
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/login/forgot/', {
      email: this.input.email.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    const { t } = this.props
    let alert = []

    if (this.props.role === 'account') {
      return (<Redirect to='/app/account' />)
    } else if (this.props.role === 'admin') {
      return (<Redirect to='/app/admin' />)
    }

    if (this.state.error) {
      alert = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    return (
      <div>
        <Helmet>
          <title>{t('forgot_password')}</title>
        </Helmet>

        <Hero />

        <section id='about'>
          <Container className='mb-5 pb-5'>
            <Row>
              {this.state.success &&
                <Col sm={12} className='text-center'>
                  <h1 className='text-center'>{t('setting_completed')}</h1>
                  <img src='/media/icon-setting-completed.svg' alt='setting completed' />
                  <p className='text-center mt-4 mb-5'>
                    {t('forot_completed_text')}
                  </p>
                </Col>}
              {!this.state.success &&
                <Col sm={12} className='text-center'>
                  <h1 className='text-center'>{t('forgot_password')}</h1>
                  <p className='text-center mb-5'>
                    {t('forgot_password_text')}
                  </p>
                  {alert}
                  <TextControl
                    className='form-control form-control-lg mr-auto ml-auto mb-4'
                    ref={(c) => (this.input.email = c)}
                    name='email'
                    placeholder={t('email_address')}
                    hasError={this.state.hasError.email}
                    disabled={this.state.loading}
                    hideHelp
                  />
                  <Button
                    color=''
                    className='sign-btn'
                    onClick={this.handleSubmit.bind(this)}
                    disabled={this.state.loading}
                  >
                    {t('send')}
                    <Spinner space='left' show={this.state.loading} />
                  </Button>
                </Col>}
            </Row>
          </Container>
        </section>
        <FooterDownload t={this.props.t} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.index.authenticated
})

export default connect(mapStateToProps, null)(ForgotPage)

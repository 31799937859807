import React, { Component } from 'react'
import ClassNames from 'classnames'
import { Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavbarText,
  NavbarToggler,
  Row,
  Col,
  Container
} from 'reactstrap'

const year = new Date().getFullYear()

class Default extends Component {
  constructor (props) {
    super(props)
    this.state = {
      navBarOpen: true
    }
  }

  compomentDidUpdate () {
    this.setState({ navBarOpen: true })
  }

  tabClass (tab) {
    return ClassNames({
      active: this.props.children.props.location.pathname === tab
    })
  }

  toggleMenu () {
    this.setState({ navBarOpen: !this.state.navBarOpen })
  }

  render () {
    return (
      <div className='admin'>
        <header>
          <Navbar color='dark' dark expand='xl' className='fixed-top'>
            <Container fluid>
              <a href='/'>
                <img className='logo product-logo navbar-logo' src='/media/logo.svg' alt='' />
              </a>
              <NavbarToggler onClick={this.toggleMenu.bind(this)} />
              <Collapse isOpen={!this.state.navBarOpen} navbar>
                <Nav className='mr-auto navbar-main' navbar>
                  <NavItem>
                    <NavLink exact to='/app/admin' activeClassName='active' className='nav-link'>
                      首頁
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink to='/app/admin/plans' activeClassName='active' className='nav-link'>
                      計畫管理
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink to='/app/admin/subscriptions' activeClassName='active' className='nav-link'>
                      訂閱管理
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink to='/app/admin/faqs' activeClassName='active' className='nav-link'>
                      問答管理
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink to='/app/admin/news' activeClassName='active' className='nav-link'>
                      消息管理
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink to='/app/admin/server_logs' activeClassName='active' className='nav-link'>
                      API 紀錄
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink to='/app/admin/users' activeClassName='active' className='nav-link'>
                      用戶管理
                    </NavLink>
                  </NavItem>
                </Nav>
                <NavbarText>
                  <NavLink to='/app/logout'>
                    登出
                  </NavLink>
                </NavbarText>
              </Collapse>
            </Container>
          </Navbar>
        </header>
        {this.props.children}

        <footer>
          <Container>
            <Row>
              <Col md={3}>
                <ul className='list-unstyled text-small'>
                  <li>
                    <Link to='/app/admin/'>
                      首頁
                    </Link>
                  </li>
                  <li>
                    <Link to='/app/logout'>
                      登出
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>

            <Row className='white-border'>
              <Col md={12}>
                <p className='copyright'>
                  © {year}  ASPEED Technology Inc. All rights reserved.
                </p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.index.user,
  authenticated: state.index.authenticated
})

export default connect(mapStateToProps, null)(Default)

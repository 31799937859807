import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
// import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import moment from 'moment'
import { Container, Button } from 'reactstrap'
import { get, put } from '../../utils/httpAgent'
import SelectControl from '../../components/select-control-lite'

class SubscriptionsPage extends Component {
  constructor () {
    super()
    this.state = {
      loadingForPayPal: false,
      successForPayPal: false,
      payPalData: [],
      payPalPage: 0,
      payPalPages: 0,
      loadingForECPay: false,
      successForECPay: false,
      eCPayData: [],
      eCPayPage: 0,
      eCPayPages: 0
    }
    this.handleFetchPayPalData = this.handleFetchPayPalData.bind(this)
    this.handleSetPayPalStatus = this.handleSetPayPalStatus.bind(this)
    this.handlePayPalSync = this.handlePayPalSync.bind(this)
    this.handleFetchECPayData = this.handleFetchECPayData.bind(this)
  }

  handleFetchPayPalData (state) {
    const page = state ? state.page + 1 : 0
    const limit = state ? state.pageSize : 10
    let sorted = state ? state.sorted : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loadingForPayPal: true
    })

    get(`/1/admin/subscriptions?page=${page}&limit=${limit}&sort=${sorted}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach((item, i) => {
            item.nextBillingTime = item.nextBillingTime ? moment(item.nextBillingTime).format('YYYY/MM/DD HH:mm') : ''
            item.updateTime = moment(item.updateTime).format('YYYY/MM/DD')
            item.createTime = moment(item.createTime).format('YYYY/MM/DD')
          })

          this.setState({
            loadingForPayPal: false,
            successForPayPal: true,
            payPalData: r.data,
            payPalPages: r.pages.total
          })
        } else {
          const state = {
            loadingForPayPal: false,
            successForPayPal: false
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  handleSetPayPalStatus (id, status) {
    let action = ''
    if (status === 'ACTIVE') {
      action = 'active'
    } else if (status === 'SUSPENDED') {
      action = 'suspend'
    } else if (status === 'CANCELLED') {
      action = 'cancel'
    }
    if (action) {
      put(`/1/admin/subscription/${id}/${action}`, {})
        .then(r => {
          if (r.success === true) {
            this.handleFetchPayPalData()
          }
        })
    }
  }

  handlePayPalSync (id) {
    get(`/1/admin/subscription/${id}/sync`, {})
      .then(r => {
        if (r.success === true) {
          this.handleFetchPayPalData()
        }
      })
  }

  handleFetchECPayData (state) {
    const page = state ? state.page + 1 : 0
    const limit = state ? state.pageSize : 10
    let sorted = state ? state.sorted : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loadingForECPay: true
    })

    get(`/1/admin/ecpay_payments?page=${page}&limit=${limit}&sort=${sorted}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach((item, i) => {
            item.nextBillingTime = item.nextBillingTime ? moment(item.nextBillingTime).format('YYYY/MM/DD HH:mm') : ''
            item.updateTime = moment(item.updateTime).format('YYYY/MM/DD')
            item.createTime = moment(item.createTime).format('YYYY/MM/DD')
          })

          this.setState({
            loadingForECPay: false,
            successForECPay: true,
            eCPayData: r.data,
            eCPayPages: r.pages.total
          })
        } else {
          const state = {
            loadingForECPay: false,
            successForECPay: false
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  handleECPaySync (id) {
    get(`/1/admin/ecpay_payment/${id}/sync`, {})
      .then(r => {
        if (r.success === true) {
          this.handleFetchECPayData()
        }
      })
  }

  render () {
    const payPalColumns = [
      {
        Header: '同步',
        accessor: '_id',
        width: 55,
        Cell: row => (
          <Button
            color=''
            className='btn-link'
            onClick={e => this.handlePayPalSync(row.value)}
          >
            <i className='lnr lnr-cloud-sync' />
          </Button>
        )
      },
      {
        Header: 'Email',
        accessor: 'email'
      }, {
        Header: '計畫',
        accessor: 'plan',
        Cell: row => (
          <span>
            {row.value.name}
          </span>
        )
      }, {
        Header: '狀態',
        accessor: 'status',
        width: 110,
        Cell: row => (
          ['ACTIVE', 'SUSPENDED'].includes(row.value)
            ? <SelectControl
                value={row.value}
                onChange={(e) => (this.handleSetPayPalStatus(row.original._id, e.target.value))}
              >
              <option value='ACTIVE'>
                啟用
              </option>
              <option value='SUSPENDED'>
                暫停
              </option>
              <option value='CANCELLED'>
                取消 (不可逆)
              </option>
            </SelectControl>
            : <span>
              {row.value === 'CANCELLED' ? '取消'
                : row.value === 'EXPIRED' ? '過期'
                  : row.value === 'APPROVAL_PENDING' ? '等待批准'
                    : row.value === 'APPROVED' ? '已批准' : '異常'}
              </span>
        )
      }, {
        Header: '下次付款日',
        accessor: 'nextBillingTime',
        width: 150
      }, {
        Header: '是否啟用',
        accessor: 'isActive',
        width: 80,
        Cell: row => (
          <span>
            <span style={{
              color: row.value === true ? '#0000FF'
                : row.value === false ? '#FF0000'
                  : '#000',
              transition: 'all .3s ease'
            }}
            >
              &#x25cf;
            </span>&nbsp;
            {row.value === true ? '啟用'
              : row.value === false ? '關閉'
                : '異常狀態'}
          </span>
        )
      }, {
        Header: '更新日期',
        accessor: 'updateTime',
        width: 100
      }, {
        Header: '建立日期',
        accessor: 'createTime',
        width: 100
      }]
    const eCPayColumns = [
      {
        Header: '同步',
        accessor: '_id',
        width: 55,
        Cell: row => (
          <Button
            color=''
            className='btn-link'
            onClick={e => this.handleECPaySync(row.value)}
          >
            <i className='lnr lnr-cloud-sync' />
          </Button>
        )
      },
      {
        Header: 'Email',
        accessor: 'email'
      }, {
        Header: '計畫',
        accessor: 'plan',
        Cell: row => (
          <span>
            {row.value.name}
          </span>
        )
      }, {
        Header: '狀態',
        accessor: 'status',
        width: 110,
        Cell: row => (
          <span>
            {row.value === 1 ? '啟用' : `失敗(${row.value})`}
          </span>
        )
      }, {
        Header: '下次付款日',
        accessor: 'nextBillingTime',
        width: 150
      }, {
        Header: '是否啟用',
        accessor: 'isActive',
        width: 80,
        Cell: row => (
          <span>
            <span style={{
              color: row.value === true ? '#0000FF' : row.value === false ? '#FF0000' : '#000',
              transition: 'all .3s ease'
            }}
            >
              &#x25cf;
            </span>&nbsp;
            {row.value === true ? '啟用' : row.value === false ? '關閉' : '異常狀態'}
          </span>
        )
      }, {
        Header: '更新日期',
        accessor: 'updateTime',
        width: 100
      }, {
        Header: '建立日期',
        accessor: 'createTime',
        width: 100
      }]

    return (
      <Container className='mb-5'>
        <Helmet>
          <title>
            現有訂閱管理
          </title>
        </Helmet>

        <h1 className='page-header'>現有訂閱管理</h1>
        <div className='row'>
          <div className='col-md-12'>
            <h3>PayPal</h3>
            <ReactTable
              manual
              data={this.state.payPalData}
              pages={this.state.payPalPages}
              loading={this.state.loadingForPayPal}
              onFetchData={this.handleFetchPayPalData}
              columns={payPalColumns}
              SubComponent={row => (
                <div className='row' style={{ padding: '20px' }}>
                  <div className='col-md-12' style={{ padding: '20px' }}>
                    <p>PayPal 計畫 ID：{row.original.paypalPlanId}</p>
                    <p>Paypal ID：{row.original.paypalId}</p>
                    {row.original.response &&
                      <p>
                        <pre>{JSON.stringify(row.original.response, null, 4)}</pre>
                      </p>}
                  </div>
                </div>
              )}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              noDataText='目前尚無任何資料'
              className='-striped -highlight'
              defaultPageSize={10}
              defaultSorted={[{ id: 'updateTime', desc: true }]}
            />
          </div>
          <div className='col-md-12 mt-5'>
            <h3>ECPay</h3>
            <ReactTable
              manual
              data={this.state.eCPayData}
              pages={this.state.eCPayPages}
              loading={this.state.loadingForECPay}
              onFetchData={this.handleFetchECPayData}
              columns={eCPayColumns}
              SubComponent={row => (
                <div className='row' style={{ padding: '20px' }}>
                  <div className='col-md-12' style={{ padding: '20px' }}>
                    <p>訂單編號：{row.original.orderNumber}</p>
                    <p>訂單 UID：{row.original.merchantTradeNo}</p>
                    {row.original.response &&
                      <p>
                        <pre>{JSON.stringify(row.original.response, null, 4)}</pre>
                      </p>}
                  </div>
                </div>
              )}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              noDataText='目前尚無任何資料'
              className='-striped -highlight'
              defaultPageSize={10}
              defaultSorted={[{ id: 'updateTime', desc: true }]}
            />
          </div>
        </div>
      </Container>
    )
  }
}

export default SubscriptionsPage

import React, { Component } from 'react'
import { put } from '../../utils/httpAgent'
import { ListGroup, ListGroupItem, Badge, Button } from 'reactstrap'

class TokensForm extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      jwt: this.props.jwt ? this.props.jwt : []
    }
  }

  removeToken (token) {
    put(`/1/admin/users/${this.props.uid}/device`, {
      token: token
    }).then(r => {
      if (r.success === true) {
        this.setState({
          jwt: r.data
        })
      }
    })
  } // end handleSubmit

  render () {
    return (
      <div>
        <Button
          className='mb-3'
          color='danger'
          onClick={this.removeToken.bind(this, 'all')}
        >
          清除所有登入設備
        </Button>
        <ListGroup
          className='mb-5'
        >
          {this.state.jwt.map(tokenObj => (
            <ListGroupItem
              key={tokenObj.mac + tokenObj.updateTime}
            >
              <Button close onClick={this.removeToken.bind(this, tokenObj.token)} />
              <p>登入資訊：{tokenObj.agent}</p>
              <p>IP：{tokenObj.ip}</p>
              <p>MAC：{tokenObj.mac}</p>
              <p>最後上線時間：{tokenObj.updateTime ? new Date(tokenObj.updateTime).toLocaleString() : ''}</p>
              <Badge color='primary' className='mr-1'>{tokenObj.device}</Badge>
              {tokenObj.isActive === true &&
                <Badge color='success'>啟用</Badge>}
            </ListGroupItem>
          ))}
        </ListGroup>
      </div>
    )
  }
}

export default TokensForm

import React from 'react'
import { NavLink } from 'react-router-dom'

class AccountMenu extends React.Component {
  render () {
    const { t } = this.props
    return (
      <section id='menu' className='float-right'>
        <NavLink className='btn btn-menu ' to='/app/account' activeClassName='active' exact>
          {t('profile')}
        </NavLink>
        <NavLink className='btn btn-menu ' to='/app/account/plans' activeClassName='active' exact>
          {t('plans')}
        </NavLink>
        <NavLink className='btn btn-menu ' to='/app/account/purchase_history' activeClassName='active' exact>
          {t('purchase_history')}
        </NavLink>
      </section>
    )
  }
}

export default AccountMenu

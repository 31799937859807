import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loginSuccess, loginError } from '../utils/userAction'
import { Helmet } from 'react-helmet'
import { Container, Row, Col, Button } from 'reactstrap'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import { post } from '../utils/httpAgent'
import { encrypt } from '../utils/rsa'
import Alert from '../shared/alert'
import Spinner from '../components/spinner'
import TextControl from '../components/text-control'
import Hero from '../components/hero'
import FooterDownload from '../components/footer-download'
import CheckboxControl from '../components/checkbox-control'
import ShowButton from '../components/show-button'

class Signup extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      showPassword: false,
      showConfirm: false,
      agree: false
    }
  }

  componentDidMount () {
    if (this.input.name) {
      this.input.name.focus()
    }
  }

  showPassword (e) {
    e.preventDefault()
    this.setState(prevState => ({ showPassword: !prevState.showPassword }))
  }

  showConfirm (e) {
    e.preventDefault()
    this.setState(prevState => ({ showConfirm: !prevState.showConfirm }))
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/signup/', {
      name: this.input.name.value(),
      email: this.input.email.value(),
      password: encrypt(this.input.password.value()),
      confirm: encrypt(this.input.confirm.value()),
      device: 'web',
      hasEncrypt: 'true'
    }).then(
      r => {
        if (r.success === true) {
          localStorage.setItem('token', r.data.token)
          delete r.data.token
          this.props.loginSuccess(r.data)
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    const { t } = this.props
    let alert = []

    if (this.state.success) {
      return (<Redirect to='/app/account' />)
    } else if (this.props.authenticated) {
      return (<Redirect to='/app/' />)
    }

    if (this.state.error) {
      alert = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    return (
      <div>
        <Helmet>
          <title>{t('sign_Up')}</title>
        </Helmet>

        <Hero />

        <section id='about'>
          <Container className='mb-5 pb-5'>
            <h1 className='text-center'>{t('create_cupola360_account')}</h1>
            <Row>
              <Col md={12} className='text-center'>
                {alert}
                <TextControl
                  className='form-control form-control-lg mr-auto ml-auto mb-3'
                  ref={(c) => (this.input.name = c)}
                  name='name'
                  placeholder={t('full_name')}
                  hasError={this.state.hasError.name}
                  disabled={this.state.loading}
                  hideHelp
                />
                <TextControl
                  className='form-control form-control-lg mr-auto ml-auto mb-3'
                  ref={(c) => (this.input.email = c)}
                  name='email'
                  placeholder={t('email_address')}
                  hasError={this.state.hasError.email}
                  disabled={this.state.loading}
                  hideHelp
                />
                <div className='password'>
                  <TextControl
                    className='form-control form-control-lg mr-auto ml-auto mb-3'
                    ref={(c) => (this.input.password = c)}
                    name='password'
                    placeholder={t('password')}
                    type={this.state.showPassword ? 'text' : 'password'}
                    hasError={this.state.hasError.password}
                    disabled={this.state.loading}
                    hideHelp
                  />
                  {!this.state.hasError.password &&
                    <ShowButton
                      show={this.state.showPassword}
                      onClick={e => this.showPassword(e)}
                    />}
                </div>
                <div className='password'>
                  <TextControl
                    className='form-control form-control-lg mr-auto ml-auto'
                    ref={(c) => (this.input.confirm = c)}
                    name='confirm'
                    placeholder={t('confirm_password')}
                    type={this.state.showConfirm ? 'text' : 'password'}
                    hasError={this.state.hasError.confirm}
                    disabled={this.state.loading}
                    hideHelp
                  />
                  {!this.state.hasError.confirm &&
                    <ShowButton
                      show={this.state.showConfirm}
                      onClick={e => this.showConfirm(e)}
                    />}
                </div>
                <CheckboxControl
                  ref={(c) => (this.input.agree = c)}
                  groupClasses='text-center signup-text mt-4 mb-3 col-12'
                  inputClasses='mr-2'
                  name='agree'
                  checked={this.state.agree}
                  onChange={(e) => (this.setState({ agree: !this.state.agree }))}
                  disabled={this.props.loading}
                  hideHelp
                >
                  {t('read_and_agree')}<a href='/#'>{t('terms_of_service')}</a>{t('space_and')}<a href='/#'>{t('privacy_policy')}</a>{t('dot')}
                </CheckboxControl>
                <Button
                  color=''
                  className='sign-btn'
                  onClick={this.handleSubmit.bind(this)}
                  disabled={this.props.loading || !this.state.agree}
                >
                  {t('sign_Up')}
                  <Spinner space='left' show={this.state.loading} />
                </Button>
                <p className='text-center signup-text'>{t('have_account')} <Link to='/app/'>{t('sign_in')}</Link></p>
              </Col>
            </Row>

          </Container>
        </section>

        <FooterDownload t={this.props.t} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.index.user,
  authenticated: state.index.authenticated,
  role: state.index.role
})

const mapDispatchToProps = dispatch => ({
  loginSuccess (user) {
    dispatch(loginSuccess(user))
  },
  loginError () {
    dispatch(loginError())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Signup)

import React, { Component } from 'react'
import { Col } from 'reactstrap'
import TextControl from '../components/text-control'

class DetailsForm extends Component {
  constructor (props) {
    super(props)
    this.input = {}
  }

  render () {
    const { t } = this.props
    return (
      <Col md={12} className='text-center'>
        <TextControl
          className='form-control form-control-lg mr-auto ml-auto mb-3'
          ref={(c) => (this.input.name = c)}
          name='name'
          placeholder={t('full_name')}
          value={this.props.name}
          onChange={(e) => (this.props.setInfo({ name: e.target.value }))}
          hasError={this.props.hasError.name}
          help={this.props.help.name}
          disabled={this.props.loading}
          hideHelp
        />
        <div className='disabled-text'>
          <TextControl
            className='form-control form-control-lg mr-auto ml-auto mb-4'
            ref={(c) => (this.input.email = c)}
            name='email'
            value={this.props.email}
            hasError={this.props.hasError.email}
            help={this.props.help.email}
            disabled
          />
          <img src='/media/icon-disable.svg' alt='disabled text' />
        </div>
      </Col>
    )
  }
}

export default DetailsForm

import React, { Component } from 'react'
import ClassNames from 'classnames'
import {
  Navbar,
  Collapse,
  Nav,
  NavbarToggler,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem } from 'reactstrap'
import { getLanguage, setLanguage } from 'react-multi-lang'

const year = new Date().getFullYear()

class Default2 extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      navBarOpen: true,
      showModal: false,
      hasError: {},
      help: {},
      email: ''
    }
    this.toggleModal = this.toggleModal.bind(this)
  }

  compomentDidUpdate () {
    this.setState({ navBarOpen: true })
  }

  tabClass (tab) {
    return ClassNames({
      active: this.props.children.props.location.pathname === tab
    })
  }

  toggleMenu () {
    this.setState({ navBarOpen: !this.state.navBarOpen })
  }

  toggleModal () {
    this.setState({ showModal: !this.state.showModal })
  }

  setLanguage (lang) {
    localStorage.setItem('lang', lang)
    setLanguage(lang)
  }

  render () {
    return (
      <div>
        <header>
          <Navbar color='light' light expand='xl' className='fixed-top'>
            <Container fluid>
              <a href='/'>
                <img className='logo product-logo navbar-logo' src='/media/logo.svg' alt='' />
              </a>

              <NavbarToggler onClick={this.toggleMenu.bind(this)} />
              <Collapse isOpen={!this.state.navBarOpen} navbar>
                <Nav className='mr-auto navbar-main' navbar />

                <UncontrolledDropdown inNavbar className='language'>
                  <DropdownToggle nav caret >
                    {getLanguage() === 'en' ? 'English' : '繁體中文'}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={this.setLanguage.bind(this, 'en')}>
                    English
                    </DropdownItem>
                    <DropdownItem onClick={this.setLanguage.bind(this, 'zhTW')}>
                    繁體中文
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Collapse>

            </Container>
          </Navbar>
        </header>

        {this.props.children}

        <footer className='no-background-color'>
          <Container>
            <Row>
              <Col md={12}>
                <p className='copyright'>
                  © {year}  ASPEED Technology Inc. All rights reserved.
                </p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    )
  }
}

export default Default2

import React from 'react'
import { connect } from 'react-redux'
import { loginSuccess, loginError } from './userAction'

export function requireAuthentication (Component) {
  class AuthenticatedComponent extends Component {
    componentDidMount () {
      try {
        var token = localStorage.getItem('token')
        if (token) {
          const jwtPayload = JSON.parse(decodeURIComponent(escape(window.atob((token.split('.')[1]).replace(/-/g, '+').replace(/_/g, '/')))))
          if (!jwtPayload._id || !jwtPayload.roles.account) {
            this.loginError()
          } else {
            this.loginSuccess(jwtPayload)
          }
        } else {
          this.loginError()
        }
      } catch (_error) {
        this.loginError()
      }
    }

    loginError () {
      this.props.loginError()
      if (window.location.pathname === '/') {
        window.location.assign('/')
      } else {
        if (window.location.search) {
          window.location.assign(`/?returnUrl=${window.location.pathname}&${window.location.search.substr(1)}`)
        } else {
          window.location.assign(`/?returnUrl=${window.location.pathname}`)
        }
      }
    }

    loginSuccess (user) {
      this.props.loginSuccess({
        authenticated: true,
        user: user.user,
        email: user.email,
        userRole: user.userRole
      })
    }

    render () {
      return (
        <Component {...this.props} />
      )
    }
  }

  const mapDispatchToProps = dispatch => ({
    loginSuccess (user) {
      dispatch(loginSuccess(user))
    },
    loginError () {
      dispatch(loginError())
    }
  })

  return connect(null, mapDispatchToProps)(AuthenticatedComponent)
}

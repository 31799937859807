import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Container, Row, Col, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import { get, deleteItem } from '../../utils/httpAgent'
import TextControl from '../../components/text-control-fillet'
import Spinner from '../../components/spinner'

class UsersPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      data: [],
      pages: null
    }
    this.handleFetchUserList = this.handleFetchUserList.bind(this)
  }

  search () {
    this.handleFetchUserList({
      page: 0,
      pageSize: this.state.pageSize,
      search: this.state.search
    })
  }

  handleFetchUserList (state) {
    const page = state ? state.page + 1 : 1
    const limit = state ? state.pageSize : 10
    const search = this.state.search ? this.state.search : ''
    let sorted = state ? state.sorted : []

    if (sorted && sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loading: true
    })

    get(`/1/admin/users?page=${page}&limit=${limit}&sort=${sorted}&search=${search}`)
      .then(r => {
        if (r.success === true) {
          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          const state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  deleteUser (uid, email) {
    if (!window.confirm(`是否確定要刪除使用者 ${email}？`)) {
      return -1
    }

    deleteItem('/1/admin/users/' + uid)
      .then(r => {
        if (r.success === true) {
          this.handleFetchUserList()
        }
      })
  }

  render () {
    const columns = [
      {
        Header: '操作',
        accessor: '_id',
        width: 55,
        Cell: row => (
          <span>
            <Link to={`/app/admin/user/${row.value}/${row.original.roles.account._id}/`} className='btn btn-sm'>
              <i className='lnr lnr-pencil' />
            </Link>
          </span>
        )
      }, {
        Header: '姓名',
        accessor: 'roles',
        width: 100,
        Cell: row => (
          <span>{row.value.account.name}</span>
        )
      }, {
        Header: 'email',
        accessor: 'email',
        width: 200
      }, {
        Header: '電話',
        accessor: 'roles',
        width: 100,
        Cell: row => (
          <span>{row.value.account.phone}</span>
        )
      }, {
        Header: '方案',
        accessor: 'plan',
        width: 100
      }, {
        Header: '是否啟用',
        accessor: 'isActive',
        width: 100,
        Cell: row => (
          <span>
            <span style={{
              color: row.value === true ? '#0000FF' : row.value === false ? '#FF0000' : '#000',
              transition: 'all .3s ease'
            }}
            >
              &#x25cf;
            </span>
            {row.value === true ? '啟用' : row.value === false ? '關閉' : '異常狀態'}
          </span>
        )
      }, {
        Header: '權限',
        accessor: 'roles',
        width: 60,
        Cell: row => (
          <span>{row.value && row.value.admin ? '管理者' : '一般'}</span>
        )
      }, {
        Header: '創立時間',
        accessor: 'timeCreated',
        Cell: row => (
          <span>{new Date(row.value).toLocaleString('tw')}</span>
        )
      }, {
        Header: '',
        accessor: '_id',
        width: 50,
        Cell: row => (
          <button className='btn btn-danger btn-sm' onClick={this.deleteUser.bind(this, row.value, row.original.email)}>
            <i className='lnr lnr-cross' />
          </button>
        )
      }]

    return (
      <Container className='mb-5'>
        <Helmet>
          <title>
            用戶管理
          </title>
        </Helmet>
        <h1 className='page-header'>用戶管理</h1>
        <Row>
          <Col md={12} lg={12} xl={12}>
            <Link to='/admin/signup_aspeed' className='btn btn-success mb-1 mr-1'>
              建立用戶
            </Link>
            {/* <Link to='/admin/signup' className='btn btn-success mb-1'>
              建立獨立用戶(獨立於 ASPEED 官網)
            </Link> */}
            <TextControl
              ref={(c) => (this.input.search = c)}
              groupClasses={{ 'form-group-inline max-width-125 ml-5': true }}
              name='search'
              value={this.state.search}
              placeholder='email'
              onChange={(e) => {
                this.setState({ search: e.target.value })
                this.search.bind(this)
              }}
              disabled={this.state.loading}
            />
            &nbsp;
            <Button
              type='button'
              color='primary'
              className='ml-1 mb-1'
              disabled={this.state.loading}
              onClick={this.search.bind(this)}
            >
              搜尋
              <Spinner space='left' show={this.state.loading} />
            </Button>
          </Col>
          <Col md={12}>
            <ReactTable
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.handleFetchUserList}
              columns={columns}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              className='-striped -highlight'
              defaultPageSize={10}
              defaultSorted={[{ id: 'createDate', desc: true }]}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default UsersPage

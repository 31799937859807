import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  type: PropTypes.number
}
const defaultProps = {
  type: 0
}

class HeroComponent extends React.Component {
  render () {
    const imageList = [
      '/media/keyimg-signin.png',
      '/media/keyimg-profile.png'
    ]
    return (
      <section id='main'>
        <div className='hero'>
          <div className='main-img d-none d-lg-block' style={{ backgroundImage: `url(${imageList[this.props.type]})` }} />
          <div className='main-img d-lg-none' style={{ backgroundImage: `url(${imageList[this.props.type]})` }} />
        </div>
      </section>
    )
  }
}

HeroComponent.propTypes = propTypes
HeroComponent.defaultProps = defaultProps

export default HeroComponent

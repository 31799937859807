import React, { Component } from 'react'
import ClassNames from 'classnames'
import { connect } from 'react-redux'
import {
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  NavbarToggler,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { getLanguage, setLanguage } from 'react-multi-lang'
import { post } from '../utils/httpAgent'

const year = new Date().getFullYear()

class Default extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      navBarOpen: true,
      showModal: false,
      hasError: {},
      help: {},
      email: ''
    }
    this.toggleModal = this.toggleModal.bind(this)
  }

  compomentDidUpdate () {
    this.setState({ navBarOpen: true })
  }

  tabClass (tab) {
    return ClassNames({
      active: this.props.children.props.location.pathname === tab
    })
  }

  toggleMenu () {
    this.setState({ navBarOpen: !this.state.navBarOpen })
  }

  toggleModal () {
    this.setState({ showModal: !this.state.showModal })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    post('/1/newsletter', {
      email: this.state.email
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            showModal: false,
            hasError: {}
          })
        } else {
          const state = {
            success: false,
            error: '',
            hasError: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
            if (state.error.includes('duplicate key')) {
              state.showModal = false
            }
          }
          this.setState(state)
        }
      }
    )
  }

  setLanguage (lang) {
    localStorage.setItem('lang', lang)
    setLanguage(lang)
  }

  render () {
    const { t } = this.props
    let roleElement = []

    if (this.props.authenticated === true) {
      roleElement = (
        <Link to='/app/account' className='btn sign-in'>
          {this.props.user}
        </Link>
      )

      if (this.props.role === 'admin') {
        roleElement = (
          <Link to='/app/admin' className='btn sign-in'>
            {this.props.user}
          </Link>
        )
      }
    } else {
      roleElement = (
        <Link to='/app/' className='btn sign-in'>
          {t('sign_in')}
        </Link>
      )
    }

    const langPath = getLanguage() === 'en' ? '' : '/tw'

    return (
      <div>
        <header>
          <Navbar color='light' light expand='xl' className='fixed-top'>
            <Container fluid>
              <a href='/'>
                <img className='logo product-logo navbar-logo' src='/media/logo.svg' alt='' />
              </a>

              <NavbarToggler onClick={this.toggleMenu.bind(this)} />
              <Collapse isOpen={!this.state.navBarOpen} navbar>
                <Nav className='mr-auto navbar-main' navbar>
                  <NavItem>
                    <NavLink href={`${langPath}/About`} className='nav-link'>
                      {t('about')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href={`${langPath}/`} className='nav-link'>
                      {t('software')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href={`${langPath}/partner`} className='nav-link'>
                      {t('partner')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href={`${langPath}/download`} className='nav-link'>
                      {t('download')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href={`${langPath}/news`} className='nav-link'>
                      {t('news')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href={`${langPath}/contact`} className='nav-link'>
                      {t('contact')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href={`${langPath}/faq_signup`} className='nav-link'>
                      {t('faq')}
                    </NavLink>
                  </NavItem>
                </Nav>

                <ul className='nav justify-content-end role-element'>
                  <li className='nav-item'>
                    {roleElement}
                  </li>
                </ul>

                <UncontrolledDropdown inNavbar className='language'>
                  <DropdownToggle nav caret>
                    {getLanguage() === 'en' ? 'English' : '繁體中文'}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={this.setLanguage.bind(this, 'en')}>
                      English
                    </DropdownItem>
                    <DropdownItem onClick={this.setLanguage.bind(this, 'zhTW')}>
                      繁體中文
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Collapse>

            </Container>
          </Navbar>
        </header>

        {this.props.children}

        <footer>
          <Container>
            <Row>
              <Col md={3}>
                <h5 className='our-product'>{t('about_company')}</h5>
                <ul className='list-unstyled text-small'>
                  <li>
                    <a href={`${langPath}/terms_service/`}>
                      {t('terms_service')}
                    </a>
                  </li>

                  <li>
                    <a href={`${langPath}/privacy_policy/`}>
                      {t('privacy_policy')}
                    </a>
                  </li>

                  <li>
                    <a href={`${langPath}/info_cookie/`}>
                      {t('cookie_statement')}
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={3}>
                <h5 className='follow'>{t('follow_us')}</h5>
                <ul className='list-unstyled text-small'>
                  <li>
                    <a href='https://www.linkedin.com/company/cupola360/' target='_blank' rel='noopener noreferrer'>
                      <img src='/media/icon-linkedin.svg' alt='linkedin' />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>

            <Row className='white-border'>
              <Col md={12}>
                <p className='copyright'>
                  © {year}  ASPEED Technology Inc. All rights reserved.
                </p>
              </Col>
            </Row>
          </Container>
        </footer>

        <div className='subscribe-mail' onClick={e => this.toggleModal()}>
          <img alt='subscribe-mail' src='/media/btn-subscribe.svg' />
        </div>

        <Modal
          isOpen={this.state.showModal}
          toggle={e => this.toggleModal()}
          modalClassName='subscribe-modal'
          backdrop={false}
        >
          <ModalHeader toggle={e => this.toggleModal()} charCode='╳'>
            {t('newsletter')}
          </ModalHeader>
          <ModalBody>
            <p>
              {t('newsletter_text')}
            </p>
            <div className={`form-group ${this.state.hasError.email ? 'has-error' : ''}`}>
              <Input
                type='email'
                name='email'
                placeholder={t('email_address')}
                onChange={e => this.setState({ email: e.target.value })}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className='btn mr-auto ml-auto'
              onClick={this.handleSubmit.bind(this)}
            >
              {t('subscribe')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.index.user,
  authenticated: state.index.authenticated,
  role: state.index.role
})

export default connect(mapStateToProps, null)(Default)

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'reactstrap'
import ReactTable from 'react-table'
import moment from 'moment'
import { get } from '../utils/httpAgent'
import TextControl from '../components/text-control-fillet'
import Button from '../components/button'
import Spinner from '../components/spinner'
import Datetime from 'react-datetime'

class ServerLogsPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      data: [],
      page: 0,
      pageSize: 10,
      search: '',
      startDate: '',
      endDate: ''
    }
    this.handleFetchDataList = this.handleFetchDataList.bind(this)
  }

  search () {
    this.handleFetchDataList({
      page: 0,
      pageSize: this.state.pageSize,
      search: this.state.search
    })
  }

  handleFetchDataList (state) {
    const page = state ? state.page + 1 : 0
    const limit = state ? state.pageSize : 10
    const search = this.state.search ? this.state.search : ''
    const startDate = this.state.startDate ? parseInt(this.state.startDate) / 1000 : ''
    const endDate = this.state.endDate ? parseInt(this.state.endDate) / 1000 : ''
    let sorted = state ? state.sorted ? state.sorted : [] : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loading: true
    })

    get(`/1/admin/server_logs?page=${page}&limit=${limit}&type=1&sort=${sorted}&search=${search}&startDate=${startDate}&endDate=${endDate}`)
      .then(r => {
        if (r.success === true && r.data) {
          r.data.forEach(item => {
            item.date = moment(item.date).format('YYYY/MM/DD HH:mm:ss')
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          const state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  render () {
    const columns = [{
      Header: '路徑',
      accessor: 'path'
    }, {
      Header: 'Email',
      accessor: 'email',
      width: 250
    }, {
      Header: '地區',
      accessor: 'country',
      width: 55
    }, {
      Header: '城市',
      accessor: 'city',
      width: 150
    }, {
      Header: '結果',
      accessor: 'success',
      width: 70,
      Cell: row => (
        <span>
          <span style={{
            color: row.value === true ? '#0000FF' : row.value === false ? '#FF0000' : '#000',
            transition: 'all .3s ease'
          }}
          >
            &#x25cf;
          </span>&nbsp;
          {row.value === true ? '成功' : row.value === false ? '失敗' : '異常狀態'}
        </span>
      )
    }, {
      Header: '發生時間',
      accessor: 'date',
      width: 180,
      Cell: row => (
        <span>{new Date(row.value).toLocaleString('tw')}</span>
      )
    }]

    return (
      <Container className='mb-5'>
        <Helmet>
          <title>
            API 記錄
          </title>
        </Helmet>

        <h1 className='page-header'>API 記錄</h1>
        <Row>
          <Col md={12} className='form-inline mb-3'>
            <TextControl
              ref={(c) => (this.input.search = c)}
              groupClasses={{ 'form-group-inline m-0': true }}
              name='search'
              placeholder='路徑、Email'
              value={this.state.search}
              onChange={(e) => {
                this.setState({ search: e.target.value })
                this.search.bind(this)
              }}
              disabled={this.state.loading}
            />
            &nbsp;
            <div className='form form-group-inline'>
              <Datetime
                ref={(c) => (this.input.startDate = c)}
                dateFormat='YYYY-MM-DD'
                timeFormat={false}
                locale='zh-tw'
                name='startDate'
                value={this.state.startDate}
                onChange={(e) => this.setState({ startDate: e.valueOf() })}
                className='form__form-group-field'
                inputProps={{
                  className: 'form-control form__form-group-field',
                  placeholder: '起日',
                  disabled: this.state.loading ? 'disabled' : undefined
                }}
              />
            </div>
            &nbsp;~&nbsp;
            <div className='form form__form-group form-group-inline'>
              <Datetime
                ref={(c) => (this.input.endDate = c)}
                dateFormat='YYYY-MM-DD'
                timeFormat={false}
                locale='zh-tw'
                name='endDate'
                value={this.state.endDate}
                onChange={(e) => this.setState({ endDate: e.valueOf() })}
                className='form__form-group-field'
                inputProps={{
                  className: 'form-control form__form-group-field',
                  placeholder: '迄日',
                  disabled: this.state.loading ? 'disabled' : undefined
                }}
              />
            </div>
            &nbsp;
            <Button
              type='button'
              className='btn btn-primary'
              disabled={this.state.loading}
              onClick={this.search.bind(this)}
            >
              搜尋
              <Spinner space='left' show={this.state.loading} />
            </Button>
          </Col>
          <Col md={12}>
            <ReactTable
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.handleFetchDataList}
              columns={columns}
              SubComponent={row => (
                <div className='row' style={{ padding: '20px' }}>
                  <div className='col-md-12' style={{ padding: '20px' }}>
                    {row.original.actor &&
                      <p>
                        IP：{row.original.actor.ip}
                      </p>}
                    <p>Input:</p>
                    <pre>{JSON.stringify(row.original.body, null, 4)}</pre>
                    <p>Output:</p>
                    <pre>{JSON.stringify(row.original.outcome, null, 4)}</pre>
                  </div>
                </div>
              )}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              className='-striped -highlight'
              defaultPageSize={this.state.pageSize}
              onPageSizeChange={(pageSize) => (this.setState({ pageSize: pageSize }))}
              defaultSorted={[{ id: 'date', desc: true }]}
            />
          </Col>
        </Row>

      </Container>
    )
  }
}

export default ServerLogsPage

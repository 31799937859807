import React, { Component } from 'react'
import { Col } from 'reactstrap'
import ShowButton from '../components/show-button'
import TextControl from '../components/text-control'

class PasswordForm extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      newPassword: '',
      confirm: ''
    }
  }

  showPassword (e) {
    e.preventDefault()
    this.setState(prevState => ({ showPassword: !prevState.showPassword }))
  }

  showConfirm (e) {
    e.preventDefault()
    this.setState(prevState => ({ showConfirm: !prevState.showConfirm }))
  }

  render () {
    const { t } = this.props
    return (
      <Col md={12} className='text-center'>
        <h6 className='text-center mt-2'>{t('change_password')}</h6>
        <div className='password'>
          <TextControl
            className='form-control form-control-lg mr-auto ml-auto mb-3'
            ref={(c) => (this.input.newPassword = c)}
            name='newPassword'
            placeholder={t('password')}
            onChange={(e) => (this.props.setInfo({ newPassword: e.target.value }))}
            type={this.state.showPassword ? 'text' : 'password'}
            hasError={this.props.hasError.newPassword}
            help={this.props.help.newPassword}
            disabled={this.props.loading}
            hideHelp
          />
          {!this.props.hasError.newPassword &&
          <ShowButton
            show={this.state.showPassword}
            onClick={e => this.showPassword(e)}
          />
          }
        </div>
        <div className='password'>
          <TextControl
            className='form-control form-control-lg mr-auto ml-auto mb-3'
            ref={(c) => (this.input.confirm = c)}
            name='confirm'
            placeholder={t('confirm_password')}
            onChange={(e) => (this.props.setInfo({ confirm: e.target.value }))}
            type={this.state.showConfirm ? 'text' : 'password'}
            hasError={this.props.hasError.confirm}
            help={this.props.help.confirm}
            disabled={this.props.loading}
            hideHelp
          />
          {!this.props.hasError.confirm &&
          <ShowButton
            show={this.state.showConfirm}
            onClick={e => this.showConfirm(e)}
          />
          }
        </div>
      </Col>
    )
  }
}

export default PasswordForm

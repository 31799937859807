import React, { Component } from 'react'
import { put } from '../../utils/httpAgent'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import SelectControl from '../../components/select-control'
import DateTimeControl from '../../components/datetime-control'

class UserForm extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      roles: this.props.roles ? this.props.roles : 'account',
      isActive: this.props.isActive ? this.props.isActive : false,
      email: this.props.email ? this.props.email : '',
      expiryDate: this.props.expiryDate ? this.props.expiryDate : '',
      permission: this.props.permission ? this.props.permission : 200
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put(`/1/admin/user/${this.props.uid}`, {
      roles: this.input.roles.value(),
      permission: this.input.permission.value(),
      expiryDate: this.state.expiryDate ? this.state.expiryDate.getTime() : '',
      isActive: this.input.isActive.value(),
      email: this.input.email.value()
    }).then(r => {
      if (r.success === true) {
        this.setState({
          success: true,
          error: '',
          loading: false,
          hasError: {}
        })
      } else {
        const state = {
          success: false,
          error: '',
          loading: false,
          hasError: {},
          help: {}
        }
        for (const key in r.errfor) {
          state.hasError[key] = true
          state.help[key] = r.errfor[key]
        }

        if (r.errors[0] !== undefined) {
          state.error = r.errors[0]
        }
        this.setState(state)
      }
    })
  } // end handleSubmit

  render () {
    let alerts = []

    if (this.state.success) {
      alerts = <Alert type='success' message='帳號資料更新成功' />
    } else if (this.state.error) {
      alerts = <Alert type='danger' message={this.state.error} />
    }

    return (
      <form onSubmit={this.handleSubmit.bind(this)} className='mb-5'>
        <legend>
          帳號資料
        </legend>
        {alerts}
        <TextControl
          className='form-control mr-auto ml-auto mb-3'
          ref={(c) => (this.input.email = c)}
          name='email'
          label='email'
          type='email'
          value={this.state.email}
          onChange={(e) => (this.setState({ email: e.target.value }))}
          hasError={this.state.hasError.email}
          help={this.state.help.email}
          disabled={this.state.loading}
        />
        <SelectControl
          className='form-control mr-auto ml-auto mb-3'
          ref={(c) => (this.input.roles = c)}
          name='roles'
          label='權限'
          value={this.state.roles}
          onChange={(e) => (this.setState({ roles: e.target.value }))}
          hasError={this.state.hasError.roles}
          help={this.state.help.roles}
          disabled={this.state.loading}
        >
          <option value='account'>
            一般
          </option>
          <option value='admin'>
            管理者
          </option>
        </SelectControl>
        <SelectControl
          className='form-control mr-auto ml-auto mb-3'
          ref={(c) => (this.input.permission = c)}
          name='permission'
          label='VIP 等級'
          value={this.state.permission}
          onChange={(e) => (this.setState({ permission: e.target.value }))}
          hasError={this.state.hasError.permission}
          help={this.state.help.permission}
          disabled={this.state.loading}
        >
          <option value='200'>
            免費
          </option>
          <option value='300'>
            月費
          </option>
          <option value='400'>
            年費
          </option>
        </SelectControl>
        <DateTimeControl
          className='form-control mr-auto ml-auto mb-3'
          ref={(c) => (this.input.expiryDate = c)}
          name='expiryDate'
          label='VIP 到期日'
          timeFormat={false}
          value={this.state.expiryDate}
          onChange={(e) => this.setState({ expiryDate: new Date(e.valueOf()) })}
          hasError={this.state.hasError.expiryDate}
          help={this.state.help.expiryDate}
          disabled={this.state.loading}
        />
        <SelectControl
          className='form-control mr-auto ml-auto mb-3'
          ref={(c) => (this.input.isActive = c)}
          name='isActive'
          label='是否啟用'
          value={this.state.isActive}
          onChange={(e) => (this.setState({ isActive: e.target.value }))}
          hasError={this.state.hasError.isActive}
          help={this.state.help.isActive}
          disabled={this.state.loading}
        >
          <option value='true'>
            啟用
          </option>
          <option value='false'>
            關閉
          </option>
        </SelectControl>
        <ControlGroup hideLabel hideHelp>
          <Button type='submit' className='btn sign-btn' disabled={this.props.loading}>
            更新
            <Spinner space='left' show={this.props.loading} />
          </Button>
        </ControlGroup>
      </form>
    )
  }
}

export default UserForm

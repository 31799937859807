import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { post } from '../../utils/httpAgent'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import SelectControl from '../../components/select-control'

class CreateFAQPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      name: '',
      lang: '',
      postTime: new Date(),
      content: []
    }
  }

  componentDidMount () {
    if (this.input.name) {
      this.input.name.focus()
    }
  }

  handleChange (event, editor) {
    const content = editor.getData()
    this.setState({ content: content })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/admin/faq', {
      no: this.input.no.value(),
      name: this.input.name.value(),
      lang: this.input.lang.value(),
      type: this.input.type.value(),
      content: this.state.content
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    let alert = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/faqs' />)
    } else if (this.state.error) {
      alert = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    return (
      <Container className='mt-5 mb-5 pb-5 form-max-width-100' fluid>
        <Helmet>
          <title>建立常見問題</title>
        </Helmet>

        <h1 className='text-center'>建立常見問題</h1>
        <Col md={12} className='text-center'>
          {alert}
        </Col>
        <Row>
          <Col md='6'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              <TextControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.name = c)}
                name='name'
                label='標題'
                placeholder='問題標題'
                onChange={(e) => (this.setState({ name: e.target.value }))}
                hasError={this.state.hasError.name}
                help={this.state.help.name}
                disabled={this.state.loading}
              />

              <SelectControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.type = c)}
                name='type'
                label='分類'
                hasError={this.state.hasError.type}
                help={this.state.help.type}
                disabled={this.state.loading}
              >
                <option value='1'>
                  帳戶註冊
                </option>
                <option value='2'>
                  軟體安裝
                </option>
                <option value='3'>
                  訂閱方案
                </option>
                <option value='4'>
                  軟體介紹
                </option>
                <option value='5'>
                  相機連線
                </option>
                <option value='6'>
                  軟體使用教學
                </option>
              </SelectControl>

              <TextControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.no = c)}
                name='no'
                label='順序'
                placeholder='問題順序'
                type='number'
                hasError={this.state.hasError.no}
                help={this.state.help.no}
                disabled={this.state.loading}
              />

              <SelectControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.lang = c)}
                name='lang'
                label='語系'
                onChange={(e) => (this.setState({ lang: e.target.value }))}
                hasError={this.state.hasError.lang}
                help={this.state.help.lang}
                disabled={this.state.loading}
              >
                <option value='tw'>
                  繁體中文
                </option>
                <option value='en'>
                  英文
                </option>
              </SelectControl>

              <div className='form-group'>
                <label className='control-label'>
                  內文
                </label>
                <div className='news'>
                  <CKEditor
                    config={{
                      ckfinder: {
                        uploadUrl: '/app/1/content/storage'
                      }
                    }}
                    editor={ClassicEditor}
                    data=''
                    onInit={editor => {}}
                    onChange={this.handleChange.bind(this)}
                    onBlur={(event, editor) => {}}
                    onFocus={(event, editor) => {}}
                  />
                </div>
              </div>

              <ControlGroup hideLabel hideHelp>
                <Button
                  className='btn sign-btn mt-3'
                  type='submit'
                  disabled={this.state.loading}
                >
                  建立
                  <Spinner space='left' show={this.state.loading} />
                </Button>
              </ControlGroup>
            </form>
          </Col>
          <Col md='6'>
            <Card>
              <CardBody className='news'>
                <h2>{this.state.name}</h2>
                <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default CreateFAQPage

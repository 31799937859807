import React, { Component } from 'react'
import { Container, Row, Col, Card, Button } from 'reactstrap'
import { Helmet } from 'react-helmet'
import { get } from '../utils/httpAgent'
import Datetime from 'react-datetime'
import styles from './css/admin.module.css'

class index extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      User: 0,
      Admin: 0,
      WeekUser: 0,
      TrialPerson: 0,
      Subscription: 0,
      EcpayPayment: 0,
      startDate: new Date(new Date(new Date().setHours(8, 0, 0, 0)).setDate(new Date().getDate() - 6)).getTime(),
      endDate: new Date(new Date(new Date().setHours(8, 0, 0, 0))).getTime()
    }
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData () {
    get(`/1/admin/count?startDate=${this.state.startDate}&endDate=${this.state.endDate}`)
      .then(r => {
        if (r.success === true) {
          this.setState({ ...r.data })
        }
      })
  }

  render () {
    return (
      <Container className='mb-5'>
        <Helmet>
          <title>系統資訊</title>
        </Helmet>
        <Row>
          <Col md={12}>
            <h1 className='page-header'>系統資訊</h1>
            <Row>
              <Col md={12} className='form-inline mb-3'>
                <div className='form form__form-group form-group-inline'>
                  <Datetime
                    ref={(c) => (this.input.startDate = c)}
                    dateFormat='YYYY-MM-DD'
                    timeFormat={false}
                    locale='zh-tw'
                    name='startDate'
                    value={this.state.startDate}
                    onChange={(e) => this.setState({ startDate: e.valueOf() })}
                    className='form__form-group-field'
                    inputProps={{
                      className: 'form-control form__form-group-field',
                      placeholder: '起日',
                      disabled: this.state.loading ? 'disabled' : undefined
                    }}
                  />
                </div>
                &nbsp;~&nbsp;
                <div className='form form__form-group form-group-inline'>
                  <Datetime
                    ref={(c) => (this.input.endDate = c)}
                    dateFormat='YYYY-MM-DD'
                    timeFormat={false}
                    locale='zh-tw'
                    name='endDate'
                    value={this.state.endDate}
                    onChange={(e) => this.setState({ endDate: e.valueOf() })}
                    className='form__form-group-field'
                    inputProps={{
                      className: 'form-control form__form-group-field',
                      placeholder: '迄日',
                      disabled: this.state.loading ? 'disabled' : undefined
                    }}
                  />
                </div>
                &nbsp;
                <Button
                  type='button'
                  color=''
                  className='btn-primary'
                  disabled={this.state.loading}
                  onClick={this.fetchData.bind(this)}
                >
                  搜尋
                </Button>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col md={3}>
                <Card body inverse className='dark-card text-center'>
                  <div className={styles['stat-value']}>
                    {this.state.WeekUser}
                  </div>
                  <div className={styles['stat-label']}>當期用戶</div>
                </Card>
              </Col>
              <Col md={3}>
                <Card body inverse className='dark-card text-center'>
                  <div className={styles['stat-value']}>
                    {this.state.TrialPerson}
                  </div>
                  <div className={styles['stat-label']}>當期試用</div>
                </Card>
              </Col>
              <Col md={3}>
                <Card body inverse className='dark-card text-center'>
                  <div className={styles['stat-value']}>
                    {this.state.Subscription}
                  </div>
                  <div className={styles['stat-label']}>當期 PayPal 訂閱</div>
                </Card>
              </Col>
              <Col md={3}>
                <Card body inverse className='dark-card text-center'>
                  <div className={styles['stat-value']}>
                    {this.state.EcpayPayment}
                  </div>
                  <div className={styles['stat-label']}>當期 ECPay 訂閱</div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col md={3}>
            <Card body inverse className='dark-card text-center'>
              <div className={styles['stat-value']}>
                {this.state.User}
              </div>
              <div className={styles['stat-label']}>用戶</div>
            </Card>
          </Col>

          <Col md={3}>
            <Card body inverse className='dark-card text-center'>
              <div className={styles['stat-value']}>
                {this.state.Admin}
              </div>
              <div className={styles['stat-label']}>管理者</div>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default index

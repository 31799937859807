import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { Container, Row, Col } from 'reactstrap'
import { put, get } from '../../utils/httpAgent'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import SelectControl from '../../components/select-control'

class EditPlanPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      name: '',
      paypalId: '',
      description: '',
      intervalUnit: '',
      intervalCount: '',
      price: '',
      ntPrice: '',
      deviceNum: '',
      permission: 200,
      setupCost: '',
      taxes: '',
      inclusiveTaxes: '',
      isActive: ''
    }
  }

  componentDidMount () {
    this.fetchData()
    if (this.input.name) {
      this.input.name.focus()
    }
  }

  fetchData () {
    get(`/1/admin/plan/${this.props.match.params.id}`)
      .then(r => {
        this.setState({
          paypalId: r.data.paypalId,
          name: r.data.name,
          description: r.data.description,
          intervalUnit: r.data.intervalUnit,
          intervalCount: r.data.intervalCount,
          price: r.data.price,
          ntPrice: r.data.ntPrice,
          deviceNum: r.data.deviceNum,
          permission: r.data.permission,
          setupCost: r.data.setupCost,
          taxes: r.data.taxes,
          inclusiveTaxes: r.data.inclusiveTaxes.toString(),
          isActive: r.data.isActive.toString()
        })
      })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put(`/1/admin/plan/${this.props.match.params.id}`, {
      paypalId: this.input.paypalId.value(),
      name: this.input.name.value(),
      description: this.input.description.value(),
      intervalUnit: this.input.intervalUnit.value(),
      intervalCount: this.input.intervalCount.value(),
      price: this.input.price.value(),
      ntPrice: this.input.ntPrice.value(),
      deviceNum: this.input.deviceNum.value(),
      permission: this.input.permission.value(),
      setupCost: this.input.setupCost.value(),
      taxes: this.input.taxes.value(),
      inclusiveTaxes: this.input.inclusiveTaxes.value(),
      isActive: this.input.isActive.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    let alert = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/plans' />)
    } else if (this.state.error) {
      alert = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    return (
      <Container className='mb-5 pb-5'>
        <Helmet>
          <title>{this.state.name} 訂閱計劃</title>
        </Helmet>

        <h1 className='text-center'>編輯訂閱計劃 {this.state.name}</h1>
        <Col md={12} className='text-center'>
          {alert}
        </Col>

        <Row>
          <Col md={12}>
            <form onSubmit={this.handleSubmit.bind(this)}>
              <TextControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.paypalId = c)}
                name='paypalId'
                label='Paypal ID'
                value={this.state.paypalId}
                onChange={(e) => (this.setState({ paypalId: e.target.value }))}
                hasError={this.state.hasError.paypalId}
                help={this.state.help.paypalId}
                disabled={this.state.loading}
              />
              <TextControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.name = c)}
                name='name'
                label='名稱'
                value={this.state.name}
                onChange={(e) => (this.setState({ name: e.target.value }))}
                hasError={this.state.hasError.name}
                help={this.state.help.name}
                disabled={this.state.loading}
              />
              <TextControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.description = c)}
                name='description'
                label='簡介'
                value={this.state.description}
                onChange={(e) => (this.setState({ description: e.target.value }))}
                hasError={this.state.hasError.description}
                help={this.state.help.description}
                disabled={this.state.loading}
              />
              <SelectControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.intervalUnit = c)}
                name='intervalUnit'
                label='計費週期'
                value={this.state.intervalUnit}
                onChange={(e) => (this.setState({ intervalUnit: e.target.value }))}
                hasError={this.state.hasError.intervalUnit}
                help={this.state.help.intervalUnit}
                disabled={this.state.loading}
              >
                <option value='DAY'>
                  天
                </option>
                <option value='WEEK'>
                  週
                </option>
                <option value='MONTH'>
                  月
                </option>
                <option value='YEAR'>
                  年
                </option>
              </SelectControl>
              <TextControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.intervalCount = c)}
                name='intervalCount'
                label='計費週期數'
                value={this.state.intervalCount}
                onChange={(e) => (this.setState({ intervalCount: e.target.value }))}
                hasError={this.state.hasError.intervalCount}
                help={this.state.help.intervalCount}
                disabled={this.state.loading}
              />
              <TextControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.price = c)}
                name='price'
                label='價格(美金)'
                value={this.state.price}
                onChange={(e) => (this.setState({ price: e.target.value }))}
                hasError={this.state.hasError.price}
                help={this.state.help.price}
                disabled={this.state.loading}
              />
              <TextControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.ntPrice = c)}
                name='ntPrice'
                label='價格(台幣)'
                value={this.state.ntPrice}
                onChange={(e) => (this.setState({ ntPrice: e.target.value }))}
                hasError={this.state.hasError.ntPrice}
                help={this.state.help.ntPrice}
                disabled={this.state.loading}
              />
              <TextControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.deviceNum = c)}
                name='deviceNum'
                label='設備數量'
                value={this.state.deviceNum}
                onChange={(e) => (this.setState({ deviceNum: e.target.value }))}
                hasError={this.state.hasError.deviceNum}
                help={this.state.help.deviceNum}
                disabled={this.state.loading}
              />
              <TextControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.permission = c)}
                name='permission'
                label='權限數值'
                value={this.state.permission}
                onChange={(e) => (this.setState({ permission: e.target.value }))}
                hasError={this.state.hasError.permission}
                help={this.state.help.permission}
                disabled={this.state.loading}
              />
              <TextControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.setupCost = c)}
                name='setupCost'
                label='設定費用'
                value={this.state.setupCost}
                onChange={(e) => (this.setState({ setupCost: e.target.value }))}
                hasError={this.state.hasError.setupCost}
                help={this.state.help.setupCost}
                disabled={this.state.loading}
              />
              <TextControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.taxes = c)}
                name='taxes'
                label='稅收(%)'
                value={this.state.taxes}
                onChange={(e) => (this.setState({ taxes: e.target.value }))}
                hasError={this.state.hasError.taxes}
                help={this.state.help.taxes}
                disabled={this.state.loading}
              />
              <SelectControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.inclusiveTaxes = c)}
                name='inclusiveTaxes'
                label='費用是否已含稅'
                value={this.state.inclusiveTaxes}
                onChange={(e) => (this.setState({ inclusiveTaxes: e.target.value }))}
                hasError={this.state.hasError.inclusiveTaxes}
                help={this.state.help.inclusiveTaxes}
                disabled={this.state.loading}
              >
                <option value='true'>
                  是
                </option>
                <option value='false'>
                  否
                </option>
              </SelectControl>
              <SelectControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.isActive = c)}
                name='isActive'
                label='是否啟用'
                value={this.state.isActive}
                onChange={(e) => (this.setState({ isActive: e.target.value }))}
                hasError={this.state.hasError.isActive}
                help={this.state.help.isActive}
                disabled={this.state.loading}
              >
                <option value='true'>
                  啟用
                </option>
                <option value='false'>
                  關閉
                </option>
              </SelectControl>
              <ControlGroup hideLabel hideHelp>
                <Button
                  type='submit'
                  className='btn sign-btn'
                  disabled={this.state.loading}
                >
                  更新
                  <Spinner space='left' show={this.state.loading} />
                </Button>
              </ControlGroup>
            </form>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default EditPlanPage

import React, { Component } from 'react'
import { useTranslation } from 'react-multi-lang'
import Login from './login/login'
import SsoReturn from './login/sso_return'
import Forgot from './login/forgot'
import Logout from './login/logout'
import Reset from './login/reset'
import Signup from './login/signup'
import Store from './utils/reducer'
import Admin from './admin/home'
import AdminUsers from './admin/users/users'
import AdminUserSetting from './admin/users/user-setting'
import AdminPlans from './admin/plans/plans'
import AdminCreatePlan from './admin/plans/create-plan'
import AdminEditPlan from './admin/plans/edit-plan'
import AdminFAQs from './admin/faq/faqs'
import AdminCreateFAQ from './admin/faq/create-faq'
import AdminEditFAQ from './admin/faq/edit-faq'
import AdminSubscriptions from './admin/subscriptions/subscriptions'
import AdminNews from './admin/news/news'
import AdminCreateNews from './admin/news/create-news'
import AdminEditNews from './admin/news/edit-news'
import AdminServerLog from './admin/server-log'
// import Account from './account/home'
import Setting from './account/setting'
import AccountPlans from './account/plans'
import AccountPurchaseHistory from './account/purchase-history'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import DefaultLayout from './layouts/Default'
import Default2Layout from './layouts/Default2'
import AdminLayout from './layouts/Admin'
// import AccountLayout from './layouts/Account'
import NotFoundPage from './NotFoundPage'
import { requireAdminAuth } from './utils/requireAdminAuth'
import { requireAuthentication } from './utils/requireAuthentication'
import { requireWeakAuth } from './utils/requireWeakAuth'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-table/react-table.css'
import 'react-datetime/css/react-datetime.css'
import './common.css'
import './home.css'
import './App.css'

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const t = useTranslation()
  return (
    <Route
      {...rest} render={props => (
        <Layout t={t}>
          <Component {...props} t={t} />
        </Layout>
      )}
    />
  )
}

class App extends Component {
  render () {
    return (
      <BrowserRouter>
        <Provider store={Store}>
          <Switch>
            <AppRoute exact path='/app/' layout={DefaultLayout} component={requireWeakAuth(Login)} />
            <AppRoute exact path='/app/sso_return' layout={Default2Layout} component={requireWeakAuth(SsoReturn)} />
            <AppRoute exact path='/app/login/forgot' layout={DefaultLayout} component={requireWeakAuth(Forgot)} />
            <AppRoute exact path='/app/login/reset/:email/:key/' layout={DefaultLayout} component={requireWeakAuth(Reset)} />
            <AppRoute exact path='/app/signup' layout={DefaultLayout} component={requireWeakAuth(Signup)} />
            <AppRoute exact path='/app/account' layout={DefaultLayout} component={requireAuthentication(Setting)} />
            <AppRoute exact path='/app/account/plans' layout={DefaultLayout} component={requireAuthentication(AccountPlans)} />
            <AppRoute exact path='/app/account/purchase_history' layout={DefaultLayout} component={requireAuthentication(AccountPurchaseHistory)} />
            <AppRoute exact path='/app/logout' layout={DefaultLayout} component={Logout} />
            <AppRoute exact path='/app/admin' layout={AdminLayout} component={requireAdminAuth(Admin)} />
            <AppRoute exact path='/app/admin/users' layout={AdminLayout} component={requireAdminAuth(AdminUsers)} />
            <AppRoute exact path='/app/admin/user/:uid/:aid/' layout={AdminLayout} component={requireAdminAuth(AdminUserSetting)} />
            <AppRoute exact path='/app/admin/faqs' layout={AdminLayout} component={requireAdminAuth(AdminFAQs)} />
            <AppRoute exact path='/app/admin/create_faq' layout={AdminLayout} component={requireAdminAuth(AdminCreateFAQ)} />
            <AppRoute exact path='/app/admin/faq/:id' layout={AdminLayout} component={requireAdminAuth(AdminEditFAQ)} />

            <AppRoute exact path='/app/admin/plans' layout={AdminLayout} component={requireAdminAuth(AdminPlans)} />
            <AppRoute exact path='/app/admin/create_plan' layout={AdminLayout} component={requireAdminAuth(AdminCreatePlan)} />
            <AppRoute exact path='/app/admin/plan/:id' layout={AdminLayout} component={requireAdminAuth(AdminEditPlan)} />

            <AppRoute exact path='/app/admin/news' layout={AdminLayout} component={requireAdminAuth(AdminNews)} />
            <AppRoute exact path='/app/admin/create_news' layout={AdminLayout} component={requireAdminAuth(AdminCreateNews)} />
            <AppRoute exact path='/app/admin/news/:id' layout={AdminLayout} component={requireAdminAuth(AdminEditNews)} />

            <AppRoute exact path='/app/admin/subscriptions' layout={AdminLayout} component={requireAdminAuth(AdminSubscriptions)} />

            <AppRoute exact path='/app/admin/server_logs' layout={AdminLayout} component={requireAdminAuth(AdminServerLog)} />

            <AppRoute exact layout={DefaultLayout} component={requireWeakAuth(NotFoundPage)} />
          </Switch>
        </Provider>
      </BrowserRouter>
    )
  }
}

export default App

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Container,
  Row,
  Col,
  Spinner,
  Button,
  Modal,
  ModalBody,
  ModalFooter } from 'reactstrap'
import { get } from '../utils/httpAgent'
import Hero from '../components/hero'
import FooterDownload from '../components/footer-download'
import AccountMenu from '../components/account-menu'
import Alert from '../shared/alert'

class PurchaseHistoryPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      showModal: false,
      data: [],
      receipt: null
    }
    this.toggleModal = this.toggleModal.bind(this)
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData () {
    this.setState({
      loading: true
    })
    get('/1/account/subscriptions/transactions')
      .then(r => {
        if (r.data) {
          this.setState({
            data: r.data,
            loading: false
          })
        } else {
          this.setState({
            loading: false
          })
        }
      })
  }

  toggleModal (receipt = null) {
    this.setState({
      showModal: !this.state.showModal,
      receipt: receipt
    })
  }

  render () {
    const { t } = this.props
    let alerts = []
    let historyDOM = []

    if (this.state.success) {
      alerts = <Alert
        type='success'
        message={t('changes_saved')}
      />
    } else if (this.state.error) {
      alerts = <Alert
        type='danger'
        message={this.state.error}
      />
    }

    if (this.state.data.length > 0) {
      this.state.data.forEach(d => {
        if (d.status === 'COMPLETED') {
          historyDOM.push(
            <tr>
              <td className='text-left'>{d.description}</td>
              <td>{new Date(d.time).toLocaleDateString()}</td>
              <td>${d.amount_with_breakdown.gross_amount.value} USD</td>
              <td>
                <Button
                  color='link'
                  onClick={e => this.toggleModal(d)}
                >
                  AST-{d.id}
                </Button>
              </td>
            </tr>
          )
        } else if (d.RtnCode === 1) {
          historyDOM.push(
            <tr>
              <td className='text-left'>{d.description}</td>
              <td>{d.process_date}</td>
              <td>{d.amount} TWD</td>
              <td>
                EC-{d.TradeNo}
              </td>
            </tr>
          )
        }
      })
    } else {
      historyDOM.push(
        <tr>
          <td colspan='4'>
            {!this.state.loading &&
              `${t('no_data')}`
            }
            {this.state.loading &&
            <Spinner
              className='p-4'
              color='info'
              style={{ width: '4rem', height: '4rem' }}
            />
            }
          </td>
        </tr>
      )
    }

    return (
      <div>
        <Helmet>
          <title>{t('purchase_history')}</title>
        </Helmet>

        <Hero type={1} />

        <AccountMenu t={t} />

        <section id='about'>
          <Container className='mb-5 pb-5'>
            <h1 className='text-center'>{t('purchase_history')}</h1>
            <Col md={12} className='text-center'>
              {alerts}
            </Col>
            <Row>
              <Col md={12} className='text-center mt-4'>
                <table className='table common-table common-table-border mb-3'>
                  <thead>
                    <tr>
                      <th className='text-left'>{t('description')}</th>
                      <th>{t('date')}</th>
                      <th>{t('total')}</th>
                      <th>{t('receipt')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyDOM}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Container>
        </section>

        <FooterDownload t={this.props.t} />

        <Modal
          isOpen={this.state.showModal}
          toggle={e => this.toggleModal()}
          modalClassName='receipt'
          backdrop={false}
        >
          <ModalBody className='pb-0'>
            <img
              className='d-block mr-auto ml-auto mt-3 mb-3'
              src='/media/cupola-360-logo.svg' alt='ok' />
            <hr />
            <h5>{t('invoice_receipt')}</h5>
            <p>
              {t('order_id')}
              <span className='black'>AST-{this.state.receipt ? this.state.receipt.id : ''}</span>
            </p>
            <hr />
            <Row>
              <Col md={6}>
                <h5>{t('billed_from')}</h5>
                <p>
                  Cupola360, Inc.
                  5F-A5, No. 16, Sec. 4, Nanjing E. Rd.,
                  Songshan Dist., Taipei City 105,
                  Taiwan (R.O.C.)
                </p>
              </Col>
              <Col md={6}>
                <h5>{t('billed_to')}</h5>
                <p>
                  {this.state.receipt ? `${this.state.receipt.payer_name.given_name} ${this.state.receipt.payer_name.surname}` : ''}
                </p>
              </Col>
            </Row>
            <hr />
            <h5>{t('purchase_date')}</h5>
            <p>
              {this.state.receipt ? new Date(this.state.receipt.time).toLocaleDateString() : ''}
            </p>
            <h5>{t('description')}</h5>
            <p>
              {this.state.receipt ? this.state.receipt.description : ''}
            </p>
            <h5>{t('amount')}</h5>
            <p>
              ${this.state.receipt ? this.state.receipt.amount_with_breakdown.gross_amount.value : ''}
            </p>
            <hr />
            <Row>
              <Col md={6}>
                <h5 className='mb-0'>{t('total').toLocaleUpperCase()}</h5>
              </Col>
              <Col md={6}>
                <h5 className='text-right mb-0'>
                  ${this.state.receipt ? this.state.receipt.amount_with_breakdown.gross_amount.value : ''}
                </h5>
              </Col>
            </Row>
            <hr />
            <p className='text-center'>
              {t('receipt_note')}
            </p>
            <hr />
            <p className='text-center'>
              {t('thanks_business')}
            </p>
            <hr />
          </ModalBody>
          <ModalFooter className='pt-0'>
            <Button
              color=''
              className='btn-outline-default mr-auto ml-auto'
              onClick={e => this.toggleModal()}
            >
              {t('close')}
            </Button>

            <Button
              color=''
              className='sign-btn btn mr-auto ml-auto'
              onClick={() => window.print()}
            >
              {t('print')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default PurchaseHistoryPage

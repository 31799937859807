import PropTypes from 'prop-types'
import React from 'react'

const propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  onClick: PropTypes.func
}
const defaultProps = {
  show: false
}

class ShowButton extends React.Component {
  render () {
    return (
      <div
        className={this.props.className}
        onClick={this.props.onClick}
      >
        {!this.props.show &&
          <img src='/media/icon-hide.svg' alt='hide password' />
        }
        {this.props.show &&
          <img src='/media/icon-view.svg' alt='show password' />
        }
      </div>
    )
  }
}

ShowButton.propTypes = propTypes
ShowButton.defaultProps = defaultProps

export default ShowButton
